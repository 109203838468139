import { SlideItemType, SlideType } from '@/utils/types/enums';
import {
  QuestionSlideItemType,
  QuestionSlideType,
} from '@/utils/types/zod/slideTypes/questionSlideSchema';
import { Fade, Flex, HStack, Text, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuestionContext } from './context/QuestionContext';
import ItemContainer from '../ItemContainer';
import ItemListContainer from '../ItemListContainer';
import SelectableAnswerItem from './components/SelectableAnswerItem';
import InfoItem from '../items/InfoItem';
import ConfirmButton from './components/ConfirmButton';
import TextAnswerItem from './components/TextAnswerItem';
import isSlideQuestionSlide from '@/utils/types/type-validation/isSlideQuestionSlide';
import { Asset } from '@/utils/types/zod/assetSchema';

type Props = {
  isHost: boolean;
  slide: QuestionSlideType;
  isPreview: boolean;
  assetList: Asset[];
  onShowAssets: (assetRefs: string[]) => void;
  currentHostAssetID: string | null | undefined;
};

export const answerGlowDuration = 2000;

function QuestionSlide({
  slide,
  assetList,
  isPreview,
  isHost,
  onShowAssets,
  currentHostAssetID,
}: Props) {
  const { t } = useTranslation();
  const toast = useToast();
  const {
    isAnswerConfirmed,
    currentSelectedAnswers,
    onSelectableAnswerClick,
    onTextAnswerChange,
    onConfirmAnswer,
    glowingVoteItem,
    hasAtleastSelectedOneAnswer,
    slideHasAtLeastOneSelectableAnswer,
    slideHasVoteItems,
  } = useQuestionContext();
  let isConfirmButtonEnabled =
    hasAtleastSelectedOneAnswer && !isAnswerConfirmed;

  //min nbr of choices are selected feature
  if (isConfirmButtonEnabled) {
    if (
      (slide.type === SlideType.QUESTION_MULTIPLE &&
        currentSelectedAnswers.length < slide.minChoices) ||
      (slide.type === SlideType.QUESTION_MULTIPLE_ORDERED &&
        currentSelectedAnswers.length < slide.correctOrderIds.length)
    ) {
      isConfirmButtonEnabled = false;
    }
  }

  const showSelectToast = () => {
    toast({
      status: 'warning',
      title: t('warning.selectAnswer'),

      position: 'top',
    });
  };
  const showConfirmToast = () => {
    toast({
      status: 'warning',
      title: t('warning.confirmChoice'),
      position: 'top',
    });
  };

  // shows answers and selection toast
  useEffect(() => {
    let timer: number;
    if (!isHost) {
      timer = window.setTimeout(() => {
        if (slideHasAtLeastOneSelectableAnswer && !hasAtleastSelectedOneAnswer)
          showSelectToast();
        else if (slideHasAtLeastOneSelectableAnswer && !isAnswerConfirmed)
          showConfirmToast();
      }, 10000);
    }
    return () => {
      if (timer) window.clearTimeout(timer);
    };
  }, [
    slideHasAtLeastOneSelectableAnswer,
    hasAtleastSelectedOneAnswer,
    isAnswerConfirmed,
  ]);

  return (
    <>
      {isSlideQuestionSlide(slide) &&
        !isPreview &&
        !isHost &&
        slideHasAtLeastOneSelectableAnswer && (
          <Fade in={true} key={`notification${isAnswerConfirmed}`} delay={0.5}>
            <Flex
              position="absolute"
              w="full"
              justifyContent="center"
              userSelect="none"
              pointerEvents="none"
              zIndex={2}
            >
              <HStack
                w="fit-content"
                color={isAnswerConfirmed ? 'livelinx.green50' : 'livelinx.text'}
                bgColor={
                  isAnswerConfirmed ? 'livelinx.green200' : 'livelinx.blue50'
                }
                px="15px"
                py="5px"
                borderRadius="5px"
              >
                <Text>
                  ⭐️{' '}
                  {isAnswerConfirmed
                    ? t('home.slide.banner.answerSubmitted.title')
                    : t('home.slide.banner.choseAnswerBanner.title')}
                </Text>
              </HStack>
            </Flex>
          </Fade>
        )}
      <Flex
        flexDir="column"
        h="100%"
        pt={isHost ? '0px' : '3%'} // time to choose notification
        pb={isHost ? '0px' : '7%'} // submit button
      >
        <ItemListContainer>
          {slide.items.map((item: QuestionSlideItemType, itemIndex: number) => {
            return (
              <ItemContainer key={itemIndex} slide={slide}>
                {item.type === SlideItemType.ANSWER_SELECTABLE && (
                  <SelectableAnswerItem
                    item={item}
                    isConfirmed={isAnswerConfirmed}
                    isSelected={currentSelectedAnswers.includes(item.id)}
                    answerIndex={
                      slide.type === SlideType.QUESTION_MULTIPLE_ORDERED
                        ? currentSelectedAnswers.indexOf(item.id)
                        : undefined
                    }
                    onMoreInfoCallback={() => {
                      if (item.assetRefs.length > 0) {
                        onShowAssets(item.assetRefs);
                      }
                    }}
                    onSelectCallback={() => onSelectableAnswerClick(item.id)}
                    isHost={isHost}
                    isGlowing={
                      !hasAtleastSelectedOneAnswer &&
                      !isAnswerConfirmed &&
                      glowingVoteItem === item
                    }
                    glowDuration={answerGlowDuration}
                    containsHostAsset={item.assetRefs.includes(
                      currentHostAssetID ?? ''
                    )}
                  />
                )}
                {item.type === SlideItemType.ANSWER_TEXT && (
                  <TextAnswerItem
                    item={item}
                    isHost={isHost}
                    onTextChange={(text: string) => {
                      onTextAnswerChange([{ answerID: item.id, text }]);
                    }}
                  />
                )}
                {item.type === SlideItemType.INFO && (
                  <InfoItem
                    onMoreInfoCallback={() => {
                      if (item.assetRefs.length > 0) {
                        onShowAssets(item.assetRefs);
                      }
                    }}
                    item={item}
                    assetList={assetList}
                    containsHostAsset={item.assetRefs.includes(
                      currentHostAssetID ?? ''
                    )}
                  />
                )}
              </ItemContainer>
            );
          })}
        </ItemListContainer>
        {!isHost &&
          !isAnswerConfirmed &&
          isSlideQuestionSlide(slide) &&
          slideHasVoteItems && (
            <Flex
              bottom="20px"
              position="absolute"
              w="full"
              justifyContent="center"
            >
              <ConfirmButton
                isEnabled={isConfirmButtonEnabled}
                zIndex={10}
                isVisible={true}
                onConfirmCallback={() => onConfirmAnswer()}
                amountSelectedAnswers={currentSelectedAnswers.length}
                maxAnswers={
                  slide.type === SlideType.QUESTION_SINGLE
                    ? 1
                    : slide.type === SlideType.QUESTION_MULTIPLE
                    ? slide.maxChoices
                    : slide.correctOrderIds.length
                }
              />
            </Flex>
          )}
      </Flex>
    </>
  );
}

export default QuestionSlide;
