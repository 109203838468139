import { User } from '@/services/API';
import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import ManageUserClientsView from '../MangeUserClientsView/ManageUserClientsView';
import UserMeetingAccessView from '../UserMeetingAccessView/UserMeetingAccessView';
import { fuzzyFilter } from '@/utils/helpers/fuzzyFilter';
import { getColumns } from './columns';
import useUserListClients from '@/services/hooks/useUserListClients';
import TanstackTableView from '@/ui/TanstackTable/TanstackTableView';
import ColumnVisibility from '@/ui/TanstackTable/Columns/ColumnVisibility';
import Filters from '@/ui/TanstackTable/Filter/Filters';
import { FilterType } from '@/ui/TanstackTable/Filter/NewFilterComponent';
import Pagination from '@/ui/TanstackTable/Pagination';
import useAdminUserTableStore from './AdminUserListTableStore';
import ActiveFilters from '@/ui/TanstackTable/Filter/ActiveFilters/ActiveFilters';
import GlobalFilter from '@/ui/TanstackTable/Filter/GlobalFilter/GlobalFilter';

type Props = {
  userList: User[];
  userListToClientNames: ReturnType<
    typeof useUserListClients
  >['userListToClientNames'];
};

export const AdminUserListView = ({
  userList,
  userListToClientNames,
}: Props) => {
  const { t } = useTranslation();
  const [
    columnFilters,
    setColumnFilters,
    columnVisibility,
    setColumnVisibility,
    columnSizing,
    setColumnSizing,
  ] = useAdminUserTableStore((state) => [
    state.columnFilters,
    state.setColumnFilters,
    state.columnVisibility,
    state.setColumnVisibility,
    state.columnSizing,
    state.setColumnSizing,
  ]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const columns = useMemo(
    () => getColumns(t, userListToClientNames, setSelectedUser),
    [t, userListToClientNames, setSelectedUser]
  );
  const table = useReactTable({
    data: userList,
    initialState: {
      pagination: {
        pageSize: 15,
      },
    },
    state: {
      globalFilter,
      columnSizing,
      columnVisibility,
      columnFilters,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: (updater) => {
      const newVisibility =
        typeof updater === 'function' ? updater(columnVisibility) : updater;
      setColumnVisibility(newVisibility);
    },
    onColumnSizingChange: (updater) => {
      const newSizing =
        typeof updater === 'function' ? updater(columnSizing) : updater;
      setColumnSizing(newSizing);
    },
    defaultColumn: {},
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onEnd',
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns: columns,
  });
  const filterTypeMappings = {
    email: FilterType.STRING,
    isTemporary: FilterType.BOOLEAN,
    last_seen: FilterType.DATE,
    createdAt: FilterType.DATE,
  };

  return (
    <Box maxW="full" p={{ base: 3, md: 3 }}>
      <HStack justifyContent="space-between" my={2}>
        <chakra.h3 fontSize="2xl" fontWeight="bold" textAlign="center">
          {t('admin.users.list.title')}{' '}
        </chakra.h3>
      </HStack>
      <Flex gap="20px" justifyContent="center" alignItems="center" mb="4">
        <VStack w="full">
          <HStack w="full">
            <ColumnVisibility table={table} />
            <Filters
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              table={table}
              filterTypeMapping={filterTypeMappings}
              filterOptionsMapping={{}}
            />
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              maxWidth="250px"
            />
            <ActiveFilters
              columnFilters={columnFilters}
              removeItemFunc={(elem) => {
                setColumnFilters(columnFilters.filter((f) => f.id !== elem.id));
              }}
              filterTypeMapping={filterTypeMappings}
            />
            <Spacer />
            <Pagination table={table} />
          </HStack>
        </VStack>
      </Flex>

      <TanstackTableView table={table} />

      <Modal
        isOpen={selectedUser ? true : false}
        onClose={() => {
          setSelectedUser(null);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box p="20px" gap="10px">
              <Heading size="sm" pb="5">
                Manage {selectedUser?.email}
              </Heading>

              {selectedUser && <ManageUserClientsView user={selectedUser} />}
              <Divider marginY="10px" />
              {selectedUser && <UserMeetingAccessView user={selectedUser} />}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
