import { composeQuestionID } from '@/features/meeting/answer/answerHelper';
import { listAnswersByQuestionID } from '@/features/meeting/answer/listAnswersByQuestionId';
import { Meeting } from '@/services/API';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import { assertIsTruthy } from '@/utils/types/type-assertion/generic/assertIsTruthy';
import { Slide } from '@/utils/types/zod/slideTypes/slideSchema';
import { Variation } from '@/utils/types/zod/variationSchema';
import { useQuery } from '@tanstack/react-query';

async function getFirstValidVariation(
  meetingID: string,
  caseID: string,
  userID: string,
  variations: Variation[]
): Promise<Variation | undefined> {
  for (const variation of variations) {
    //get the question, check if the user answer is the one we want
    const allAnswers = await listAnswersByQuestionID(
      composeQuestionID(meetingID, caseID, variation.conditionKey)
    );
    if (!allAnswers) {
      continue;
    }
    //NOTE: If ever a user can send multiple answers, change this
    const userAnswer = allAnswers.find((el) => el.userID === userID);
    if (!userAnswer) {
      continue;
    }
    //NOTE MULTIVOTE: If at some point we want to add conditions according to multivote, we'll have to add a check here
    if (userAnswer.answerIndexes[0] === variation.conditionValue) {
      return variation;
    }
  }
  return undefined;
}

export type UseSlideVariationReturnType = {
  slideHasVariations: boolean;
  isLoading: boolean;
  slideVariation: Variation | null | undefined;
};

export default function useSlideVariations(
  currentCaseId: string | null | undefined,
  meeting: Meeting | undefined,
  isUserHost: boolean,
  slide: Slide | undefined,
  allVariations: Variation[],
  userID: string | undefined
): UseSlideVariationReturnType {
  const slideHasVariations = (slide?.variationIds?.length || 0) > 0;

  const slideVariationQuery = useQuery({
    queryKey: ['useSlideVariations'],
    refetchOnWindowFocus: false,
    enabled:
      !isUserHost &&
      !!currentCaseId &&
      !!slide &&
      !!meeting &&
      slideHasVariations &&
      allVariations.length > 0,
    queryFn: async () => {
      assertIsTruthy(currentCaseId);
      assertIsTruthy(slide);
      assertIsTruthy(meeting);
      const possibleVariations = allVariations.filter((variation) =>
        slide.variationIds.includes(variation.variationId)
      );
      if (!userID) {
        captureSentry({
          title:
            'No userID while fetching slideVariations. This is not normal.',
        });
        return null;
      }
      assertIsTruthy(userID);
      const slideVariation = await getFirstValidVariation(
        //NIKOTODO: This will probably brake in caseView
        meeting.id,
        currentCaseId,
        userID,
        possibleVariations
      );
      if (!slideVariation) {
        //NOTE: this is not an error for sentry
        // captureSentry({
        //   title:
        //     'No Slide variation found. This means that: either not all variations have been added in the patient case or the user did not answer the required question.',
        //   detail: {
        //     meetingID: meeting.id,
        //     caseID: currentCaseId,
        //     userID,
        //     slideID: slide.id,
        //   },
        // });
        return null;
      }
      console.log('variation result', slideVariation);
      return slideVariation;
    },
  });

  if (isUserHost || !slide || !slideHasVariations) {
    return {
      slideHasVariations: false,
      isLoading: false,
      slideVariation: null,
    };
  }

  return {
    slideHasVariations,
    isLoading: slideVariationQuery.isLoading,
    slideVariation: slideVariationQuery.data,
  };
}
