import { Answer } from '@/services/API';
import { NoAnswerItem } from '@/utils/errors/NoAnswerItem';
import { NoUserAnswer } from '@/utils/errors/NoUserAnswer';
import isSlideItemSelectableAnswerItem from '@/utils/types/type-validation/isSlideItemSelectableAnswerItem';
import isSlideQuestionSlide from '@/utils/types/type-validation/isSlideQuestionSlide';
import { SelectableAnswerSlideItemType } from '@/utils/types/zod/slideTypes/slideItemTypes/answerSlideItemTypes/selectableAnswerSlideItemSchema';
import { Slide } from '@/utils/types/zod/slideTypes/slideSchema';

export default async function getAnswerItemsAccordingToUserAnswer(
  userID: string,
  slideID: string,
  allAnswers: Answer[],
  slides: Slide[]
) {
  const userAnswer = allAnswers.find((el) => el.userID === userID);

  if (!userAnswer) throw new NoUserAnswer();

  const questionSlide = slides.find((slide) => slide.id === slideID);
  if (!questionSlide || !isSlideQuestionSlide(questionSlide)) {
    throw new Error(
      'TopRightItem - questionSlide is undefined or it is not a question slide'
    );
  }
  const selectableItems: SelectableAnswerSlideItemType[] =
    questionSlide.items.filter(isSlideItemSelectableAnswerItem);
  const answerItems = selectableItems.filter((item) =>
    userAnswer.answerIndexes.includes(item.id)
  );
  if (userAnswer.answerIndexes.length !== answerItems.length)
    throw new NoAnswerItem();
  return answerItems;
}
