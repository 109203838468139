import { CaseMap } from '@/features/caseCluster/caseCluster.types';
import { GetCaseIndexByCaseID } from '@/features/caseCluster/helpers/caseCluster.helpers';
import useCaseContent from '@/features/caseCluster/hooks/useCaseClusterContent';
import useMeetingStatsData from '@/features/meeting-stats/hooks/useMeetingStatsData';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { CaseCluster, Meeting, User } from '@/services/API';
import useMeetingSubscription from '@/services/subscriptions/useMeetingSubscription';
import { MeetingSelectors } from '@/store/slices/meeting';
import { useAppSelector } from '@/store/StoreHooks';
import { PatientCase } from '@/utils/types/zod/patientCaseSchema';
import { Variation } from '@/utils/types/zod/variationSchema';
import { createContext, useContext, useMemo } from 'react';
import useMeetingDebugger from '../answer/hooks/useMeetingDebugger';
import useMeetingInteractionFunctions from '../hooks/useMeetingInteractionFunctions';
import useSlideVariations from '@/components/CaseSlide/hooks/useSlideVariation';
import useMeetingParticipants from '../hooks/useMeetingParticipantsList';
import useRedirectHomeIfMeetingIsArchived from '../answer/hooks/useRedirectHomeIfMeetingIsArchived';

type Props = {
  children: React.ReactNode;
  forcedPatientCase?: PatientCase;
  isPreview?: boolean;
};

type CurrentMeetingDataContextType = {
  meeting: Meeting | undefined;
  user: User | null | undefined;
  hostID: string | undefined;
  host: User | null | undefined;
  caseCluster: CaseCluster | null | undefined;
  caseClusterName: string | undefined;
  caseMap: CaseMap;
  currentCase: PatientCase | undefined;
  currentCaseId: string | null | undefined;
  currentCaseIndex: number;
  isCaseLoading: boolean;
  slideIndex: number | null | undefined;
  slideID: string | null | undefined;
  slideName: string | undefined;
  isUserHost: boolean;
  meetingStatsData: ReturnType<typeof useMeetingStatsData>;
  variationList: Variation[];
  connectedUserList: ReturnType<typeof useMeetingParticipants>;
  meetingInteractions: ReturnType<typeof useMeetingInteractionFunctions>;
  isPreview: boolean;
  slideVariationDetails: ReturnType<typeof useSlideVariations>;
  currentHostAssetID: string | null | undefined;
};

export const CurrentMeetingDataContext = createContext<
  CurrentMeetingDataContextType | undefined
>(undefined);

export default function CurrentMeetingDataContextProvider({
  children,
  forcedPatientCase,
  isPreview,
}: Props) {
  const { user: currentUser } = useUserAuthenticationContext();
  const currentMeeting = useAppSelector(MeetingSelectors.getCurrentMeeting);
  const caseCluster = currentMeeting?.caseCluster;
  const { caseMap, isCaseLoading } = useCaseContent(caseCluster?.cases || []);
  const userID = currentUser?.id;
  const hostID = currentMeeting?.hostID;
  const currentCaseIndex = GetCaseIndexByCaseID(
    currentMeeting?.currentCase,
    caseCluster
  );
  let currentCase: PatientCase | undefined =
    caseMap && currentMeeting && currentMeeting.currentCase
      ? caseMap[currentMeeting.currentCase]
      : undefined;
  if (forcedPatientCase) {
    currentCase = forcedPatientCase;
  }
  const variationList: Variation[] = currentCase?.variations || [];
  let isUserHost = userID && hostID && hostID === userID ? true : false;
  if (isPreview) {
    isUserHost = true;
  }
  const meetingStatsData = useMeetingStatsData(currentMeeting?.id, true);
  const currentSlide = useMemo(() => {
    if (!currentCase || !currentMeeting) return undefined;
    return currentCase.slides[currentMeeting.currentSlide || 0];
  }, [currentCase, currentMeeting?.currentSlide]);
  const connectedUserList = useMeetingParticipants(currentMeeting?.id);

  const meetingInteractions = useMeetingInteractionFunctions(
    currentMeeting,
    isPreview ?? false
  );

  const slideVariationDetails = useSlideVariations(
    currentMeeting?.currentCase,
    currentMeeting,
    isUserHost,
    currentSlide,
    variationList,
    userID
  );

  const currentHostAssetID = !isPreview ? currentMeeting?.currentAssetID : '';

  // use meeting subscription to track changes in meeting at runtime
  useMeetingSubscription();
  //NOTE: we don't need sync checks during preview
  useMeetingDebugger(isPreview ? undefined : currentMeeting);
  useRedirectHomeIfMeetingIsArchived({
    meeting: isPreview ? undefined : currentMeeting,
  });

  return (
    <CurrentMeetingDataContext.Provider
      value={{
        meeting: currentMeeting,
        user: currentUser,
        hostID: currentMeeting?.hostID,
        host: currentMeeting?.host,
        caseCluster,
        caseClusterName: caseCluster?.name,
        caseMap,
        currentCase,
        currentCaseId: currentMeeting?.currentCase,
        currentCaseIndex,
        isCaseLoading: isCaseLoading || !currentUser,
        slideIndex: currentMeeting?.currentSlide,
        slideName: currentSlide ? currentSlide.title : undefined,
        slideID: currentSlide ? currentSlide.id : undefined,
        isUserHost,
        meetingStatsData,
        variationList,
        connectedUserList,
        meetingInteractions,
        isPreview: isPreview ?? false,
        slideVariationDetails,
        currentHostAssetID,
      }}
    >
      {children}
    </CurrentMeetingDataContext.Provider>
  );
}

export const useCurrentMeetingDataContext = () => {
  const context = useContext(CurrentMeetingDataContext);
  if (context === undefined) {
    throw new Error(
      'useCurrentMeetingDataContext must be used within a CurrentMeetingDataContextProvider'
    );
  }
  return context;
};
