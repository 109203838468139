import { SlideType } from '../enums';
import { QuestionSlideType } from '../zod/slideTypes/questionSlideSchema';
import { Slide } from '../zod/slideTypes/slideSchema';
import { QuestionTypes } from '../enums';

export default function isSlideQuestionSlide(
  slide: Slide
): slide is QuestionSlideType {
  return isTypeOfSlideQuestionType(slide.type);
}

function isTypeOfSlideQuestionType(type: SlideType): type is QuestionTypes {
  return (
    type === SlideType.QUESTION_SINGLE ||
    type === SlideType.QUESTION_MULTIPLE ||
    type === SlideType.QUESTION_MULTIPLE_ORDERED
  );
}
