import { composeQuestionID } from '@/features/meeting/answer/answerHelper';
import { listAnswersByQuestionID } from '@/features/meeting/answer/listAnswersByQuestionId';
import getAnswerItemsAccordingToUserAnswer from '@/pages/caseCluster/utils/getAnswerItemsAccordingToUserAnswer';
import { Meeting } from '@/services/API';
import { NoUserAnswer } from '@/utils/errors/NoUserAnswer';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import { assertIsTruthy } from '@/utils/types/type-assertion/generic/assertIsTruthy';
import { PatientCase } from '@/utils/types/zod/patientCaseSchema';
import { useQuery } from '@tanstack/react-query';

export default function usePreviousQuestionSlideUserAnswer(
  meeting: Meeting | undefined | null,
  currentCaseId: string | undefined | null,
  currentCase: PatientCase | undefined | null,
  slideID: string,
  userID: string | undefined | null
) {
  const query = useQuery({
    queryKey: ['getPreviousQuestionSlideUserAnswers'],
    refetchOnWindowFocus: false,
    enabled: !!userID && !!meeting?.id && !!currentCaseId && !!currentCase,
    queryFn: async () => {
      assertIsTruthy(currentCase);
      assertIsTruthy(userID);
      assertIsTruthy(meeting?.id);
      assertIsTruthy(currentCaseId);
      assertIsTruthy(currentCase.slides.find((s) => s.id === slideID));

      const questionID = composeQuestionID(meeting.id, currentCaseId, slideID);
      const allAnswers = (await listAnswersByQuestionID(questionID)) || [];
      try {
        const answerItems = await getAnswerItemsAccordingToUserAnswer(
          userID,
          slideID,
          allAnswers,
          currentCase.slides
        );
        return answerItems;
      } catch (e) {
        //This is an error I don't want to capture
        if (e instanceof NoUserAnswer) {
          console.log('No user answer found');
          return [];
        }

        captureSentry({
          title: 'usePreviousQuestionSlideUserAnswer error',
          error: e instanceof Error ? e : undefined,
          detail: {
            allAnswers,
            questionID,
            slideID,
            userID,
            currentCase,
          },
        });

        console.error(e);
        return [];
      }
    },
  });

  return query;
}
