import { useCurrentSlideData } from '@/pages/caseCluster/CaseExplorer/currentSlideData.store';
import { useSlideShowStore } from '../SlideShowSlide/slideShowData.store';
import { SlideType } from '@/utils/types/enums';

export const useSlideID = () => {
  const currentSlide = useCurrentSlideData((state) => state.currentSlide);
  const currentSlideWithVariation = useCurrentSlideData(
    (state) => state.currentSlideWithVariation
  );
  const slideShowData = useSlideShowStore();

  if (!currentSlide) return undefined;
  let result = currentSlide.id;
  if (currentSlide && currentSlide.type === SlideType.SLIDE_SHOW) {
    const slideShowSlide =
      slideShowData.subSlides[slideShowData.currentSubSlideIdx];
    result += ` / ${slideShowSlide.id}`;
  }
  if (
    currentSlideWithVariation &&
    currentSlideWithVariation.id !== currentSlide?.id
  ) {
    result += ` / ${currentSlideWithVariation.id}`;
    if (currentSlideWithVariation.type === SlideType.SLIDE_SHOW) {
      const slideShowSlide =
        slideShowData.subSlides[slideShowData.currentSubSlideIdx];
      result += ` / ${slideShowSlide.id}`;
    }
  }
  return result;
};
