/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://i2n6dof7ivgctbcxliisfsmqnq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ngwy6cuogrga7hb6uocspqkjiy",
    "aws_cognito_identity_pool_id": "eu-central-1:222ff9b0-8f70-45e2-94c8-470866e1d5f2",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_lXQn3UWiY",
    "aws_user_pools_web_client_id": "m0kf41uru3gpgnii8ie15orok",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
