import { CaseMap } from '@/features/caseCluster/caseCluster.types';
import isSlideAnamneseSlide from '@/utils/types/type-validation/isSlideAnamneseSlide';
import { PatientCase } from '@/utils/types/zod/patientCaseSchema';
import { AnamneseSlideItemType } from '@/utils/types/zod/slideTypes/slideItemTypes/anamneseItemSchema';

export const getAnamenseItemFromCaseMap = (
  caseMap: CaseMap,
  caseID: string
): AnamneseSlideItemType | undefined => {
  if (caseMap[caseID]) {
    return getAnamneseItemFromCase(caseMap[caseID]);
  }
  return undefined;
};

export const getAnamneseItemFromCase = (
  patientCase: PatientCase
): AnamneseSlideItemType | undefined => {
  const anamneseSlide = patientCase.slides.find(isSlideAnamneseSlide);

  if (!anamneseSlide) {
    return undefined;
  }
  return anamneseSlide.items[0];
};
