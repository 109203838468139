/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    firstname
    lastname
    email
    createdAt
    updatedAt
    meetingAccess {
      items {
        id
        owner
        userID
        meetingID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    last_seen
    currentMeetingID
    currentMeeting {
      id
      owner
      name
      caseClusterID
      hostID
      host {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          startDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          currentAssetID
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          streamUrl
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      hostFullName
      createdAt
      updatedAt
      eventDate
      startDate
      endDate
      eventLocation
      participants {
        items {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        nextToken
        __typename
      }
      userAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      guestUrlCode
      previewCode
      clientIDs
      currentCase
      currentSlide
      currentAssetID
      caseCluster {
        id
        owner
        name
        cases
        createdAt
        updatedAt
        isMock
        __typename
      }
      caseStats {
        items {
          id
          owner
          meetingID
          caseId
          version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      meetingStats {
        id
        owner
        meetingID
        casePresentationHistory
        version
        amountParticipants
        createdAt
        updatedAt
        __typename
      }
      purchaseOrderNbr
      meetingNotes
      meetingType
      isMock
      isInTesting
      isArchived
      isDemo
      streamUrl
      __typename
    }
    isTemporary
    owner
    isMock
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $id: ID
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        startDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        currentAssetID
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          amountParticipants
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        streamUrl
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getUserClient = /* GraphQL */ `query GetUserClient($id: ID!) {
  getUserClient(id: $id) {
    id
    owner
    clientID
    client {
      id
      clientName
      createdAt
      updatedAt
      owner
      __typename
    }
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserClientQueryVariables,
  APITypes.GetUserClientQuery
>;
export const listUserClients = /* GraphQL */ `query ListUserClients(
  $filter: ModelUserClientFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      clientID
      client {
        id
        clientName
        createdAt
        updatedAt
        owner
        __typename
      }
      userID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserClientsQueryVariables,
  APITypes.ListUserClientsQuery
>;
export const getClient = /* GraphQL */ `query GetClient($id: ID!) {
  getClient(id: $id) {
    id
    clientName
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientQueryVariables, APITypes.GetClientQuery>;
export const listClients = /* GraphQL */ `query ListClients(
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
) {
  listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientName
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClientsQueryVariables,
  APITypes.ListClientsQuery
>;
export const getUserMeetingAccess =
  /* GraphQL */ `query GetUserMeetingAccess($id: ID!) {
  getUserMeetingAccess(id: $id) {
    id
    owner
    userID
    meetingID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetUserMeetingAccessQueryVariables,
    APITypes.GetUserMeetingAccessQuery
  >;
export const listUserMeetingAccesses =
  /* GraphQL */ `query ListUserMeetingAccesses(
  $filter: ModelUserMeetingAccessFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserMeetingAccesses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      userID
      meetingID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListUserMeetingAccessesQueryVariables,
    APITypes.ListUserMeetingAccessesQuery
  >;
export const userMeetingAccessByUserID =
  /* GraphQL */ `query UserMeetingAccessByUserID(
  $userID: ID!
  $meetingID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserMeetingAccessFilterInput
  $limit: Int
  $nextToken: String
) {
  userMeetingAccessByUserID(
    userID: $userID
    meetingID: $meetingID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      userID
      meetingID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.UserMeetingAccessByUserIDQueryVariables,
    APITypes.UserMeetingAccessByUserIDQuery
  >;
export const userMeetingAccessByMeetingID =
  /* GraphQL */ `query UserMeetingAccessByMeetingID(
  $meetingID: ID!
  $userID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserMeetingAccessFilterInput
  $limit: Int
  $nextToken: String
) {
  userMeetingAccessByMeetingID(
    meetingID: $meetingID
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      userID
      meetingID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.UserMeetingAccessByMeetingIDQueryVariables,
    APITypes.UserMeetingAccessByMeetingIDQuery
  >;
export const getMeeting = /* GraphQL */ `query GetMeeting($id: ID!) {
  getMeeting(id: $id) {
    id
    owner
    name
    caseClusterID
    hostID
    host {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        startDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        currentAssetID
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          amountParticipants
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        streamUrl
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    hostFullName
    createdAt
    updatedAt
    eventDate
    startDate
    endDate
    eventLocation
    participants {
      items {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          startDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          currentAssetID
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          streamUrl
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      nextToken
      __typename
    }
    userAccess {
      items {
        id
        owner
        userID
        meetingID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    guestUrlCode
    previewCode
    clientIDs
    currentCase
    currentSlide
    currentAssetID
    caseCluster {
      id
      owner
      name
      cases
      createdAt
      updatedAt
      isMock
      __typename
    }
    caseStats {
      items {
        id
        owner
        meetingID
        caseId
        assetViews {
          nextToken
          __typename
        }
        slides {
          nextToken
          __typename
        }
        version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    meetingStats {
      id
      owner
      meetingID
      casePresentationHistory
      version
      amountParticipants
      createdAt
      updatedAt
      __typename
    }
    purchaseOrderNbr
    meetingNotes
    meetingType
    isMock
    isInTesting
    isArchived
    isDemo
    streamUrl
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMeetingQueryVariables,
  APITypes.GetMeetingQuery
>;
export const listMeetings = /* GraphQL */ `query ListMeetings(
  $filter: ModelMeetingFilterInput
  $limit: Int
  $nextToken: String
) {
  listMeetings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      name
      caseClusterID
      hostID
      host {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          startDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          currentAssetID
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          streamUrl
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      hostFullName
      createdAt
      updatedAt
      eventDate
      startDate
      endDate
      eventLocation
      participants {
        items {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        nextToken
        __typename
      }
      userAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      guestUrlCode
      previewCode
      clientIDs
      currentCase
      currentSlide
      currentAssetID
      caseCluster {
        id
        owner
        name
        cases
        createdAt
        updatedAt
        isMock
        __typename
      }
      caseStats {
        items {
          id
          owner
          meetingID
          caseId
          version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      meetingStats {
        id
        owner
        meetingID
        casePresentationHistory
        version
        amountParticipants
        createdAt
        updatedAt
        __typename
      }
      purchaseOrderNbr
      meetingNotes
      meetingType
      isMock
      isInTesting
      isArchived
      isDemo
      streamUrl
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingsQueryVariables,
  APITypes.ListMeetingsQuery
>;
export const getUserMeetingJoin =
  /* GraphQL */ `query GetUserMeetingJoin($id: ID!) {
  getUserMeetingJoin(id: $id) {
    id
    owner
    userID
    meetingID
    user {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        startDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        currentAssetID
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          amountParticipants
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        streamUrl
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    isCurrentlyInMeeting
    lastPingDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetUserMeetingJoinQueryVariables,
    APITypes.GetUserMeetingJoinQuery
  >;
export const listUserMeetingJoins = /* GraphQL */ `query ListUserMeetingJoins(
  $filter: ModelUserMeetingJoinFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserMeetingJoins(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      userID
      meetingID
      user {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          startDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          currentAssetID
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          streamUrl
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      isCurrentlyInMeeting
      lastPingDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserMeetingJoinsQueryVariables,
  APITypes.ListUserMeetingJoinsQuery
>;
export const userMeetingJoinByUserID =
  /* GraphQL */ `query UserMeetingJoinByUserID(
  $userID: ID!
  $meetingID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserMeetingJoinFilterInput
  $limit: Int
  $nextToken: String
) {
  userMeetingJoinByUserID(
    userID: $userID
    meetingID: $meetingID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      userID
      meetingID
      user {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          startDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          currentAssetID
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          streamUrl
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      isCurrentlyInMeeting
      lastPingDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.UserMeetingJoinByUserIDQueryVariables,
    APITypes.UserMeetingJoinByUserIDQuery
  >;
export const userMeetingsByMeetingID =
  /* GraphQL */ `query UserMeetingsByMeetingID(
  $meetingID: ID!
  $userID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserMeetingJoinFilterInput
  $limit: Int
  $nextToken: String
) {
  userMeetingsByMeetingID(
    meetingID: $meetingID
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      userID
      meetingID
      user {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          startDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          currentAssetID
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          streamUrl
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      isCurrentlyInMeeting
      lastPingDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.UserMeetingsByMeetingIDQueryVariables,
    APITypes.UserMeetingsByMeetingIDQuery
  >;
export const getAnswer = /* GraphQL */ `query GetAnswer($id: ID!) {
  getAnswer(id: $id) {
    id
    owner
    questionID
    userID
    meetingID
    answerIndexes
    answerTexts
    createdAt
    updatedAt
    user {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        startDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        currentAssetID
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          amountParticipants
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        streamUrl
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAnswerQueryVariables, APITypes.GetAnswerQuery>;
export const listAnswers = /* GraphQL */ `query ListAnswers(
  $filter: ModelAnswerFilterInput
  $limit: Int
  $nextToken: String
) {
  listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      questionID
      userID
      meetingID
      answerIndexes
      answerTexts
      createdAt
      updatedAt
      user {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          startDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          currentAssetID
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          streamUrl
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAnswersQueryVariables,
  APITypes.ListAnswersQuery
>;
export const answersByQuestionID = /* GraphQL */ `query AnswersByQuestionID(
  $questionID: String!
  $userID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAnswerFilterInput
  $limit: Int
  $nextToken: String
) {
  answersByQuestionID(
    questionID: $questionID
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      questionID
      userID
      meetingID
      answerIndexes
      answerTexts
      createdAt
      updatedAt
      user {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          startDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          currentAssetID
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          streamUrl
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AnswersByQuestionIDQueryVariables,
  APITypes.AnswersByQuestionIDQuery
>;
export const answersByMeetingID = /* GraphQL */ `query AnswersByMeetingID(
  $meetingID: ID!
  $userID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAnswerFilterInput
  $limit: Int
  $nextToken: String
) {
  answersByMeetingID(
    meetingID: $meetingID
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      questionID
      userID
      meetingID
      answerIndexes
      answerTexts
      createdAt
      updatedAt
      user {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          startDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          currentAssetID
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          streamUrl
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AnswersByMeetingIDQueryVariables,
  APITypes.AnswersByMeetingIDQuery
>;
export const getCaseCluster = /* GraphQL */ `query GetCaseCluster($id: ID!) {
  getCaseCluster(id: $id) {
    id
    owner
    name
    cases
    createdAt
    updatedAt
    isMock
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCaseClusterQueryVariables,
  APITypes.GetCaseClusterQuery
>;
export const listCaseClusters = /* GraphQL */ `query ListCaseClusters(
  $filter: ModelCaseClusterFilterInput
  $limit: Int
  $nextToken: String
) {
  listCaseClusters(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      name
      cases
      createdAt
      updatedAt
      isMock
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCaseClustersQueryVariables,
  APITypes.ListCaseClustersQuery
>;
export const getMessage = /* GraphQL */ `query GetMessage($id: ID!) {
  getMessage(id: $id) {
    id
    owner
    channelID
    author
    body
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMessageQueryVariables,
  APITypes.GetMessageQuery
>;
export const listMessages = /* GraphQL */ `query ListMessages(
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      channelID
      author
      body
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMessagesQueryVariables,
  APITypes.ListMessagesQuery
>;
export const messagesByChannelID = /* GraphQL */ `query MessagesByChannelID(
  $channelID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  messagesByChannelID(
    channelID: $channelID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      channelID
      author
      body
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MessagesByChannelIDQueryVariables,
  APITypes.MessagesByChannelIDQuery
>;
export const getMeetingStats = /* GraphQL */ `query GetMeetingStats($id: ID!) {
  getMeetingStats(id: $id) {
    id
    owner
    meetingID
    casePresentationHistory
    version
    amountParticipants
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMeetingStatsQueryVariables,
  APITypes.GetMeetingStatsQuery
>;
export const listMeetingStats = /* GraphQL */ `query ListMeetingStats(
  $filter: ModelMeetingStatsFilterInput
  $limit: Int
  $nextToken: String
) {
  listMeetingStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      meetingID
      casePresentationHistory
      version
      amountParticipants
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingStatsQueryVariables,
  APITypes.ListMeetingStatsQuery
>;
export const meetingStatsByMeeting = /* GraphQL */ `query MeetingStatsByMeeting(
  $meetingID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMeetingStatsFilterInput
  $limit: Int
  $nextToken: String
) {
  meetingStatsByMeeting(
    meetingID: $meetingID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      meetingID
      casePresentationHistory
      version
      amountParticipants
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MeetingStatsByMeetingQueryVariables,
  APITypes.MeetingStatsByMeetingQuery
>;
export const getCaseStats = /* GraphQL */ `query GetCaseStats($id: ID!) {
  getCaseStats(id: $id) {
    id
    owner
    meetingID
    caseId
    assetViews {
      items {
        id
        owner
        assetId
        userID
        timeWatched
        version
        createdAt
        updatedAt
        caseStatsAssetViewsId
        __typename
      }
      nextToken
      __typename
    }
    slides {
      items {
        id
        owner
        slideId
        time
        version
        createdAt
        updatedAt
        caseStatsSlidesId
        __typename
      }
      nextToken
      __typename
    }
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCaseStatsQueryVariables,
  APITypes.GetCaseStatsQuery
>;
export const listCaseStats = /* GraphQL */ `query ListCaseStats(
  $filter: ModelCaseStatsFilterInput
  $limit: Int
  $nextToken: String
) {
  listCaseStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      meetingID
      caseId
      assetViews {
        items {
          id
          owner
          assetId
          userID
          timeWatched
          version
          createdAt
          updatedAt
          caseStatsAssetViewsId
          __typename
        }
        nextToken
        __typename
      }
      slides {
        items {
          id
          owner
          slideId
          time
          version
          createdAt
          updatedAt
          caseStatsSlidesId
          __typename
        }
        nextToken
        __typename
      }
      version
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCaseStatsQueryVariables,
  APITypes.ListCaseStatsQuery
>;
export const caseStatsByMeeting = /* GraphQL */ `query CaseStatsByMeeting(
  $meetingID: ID!
  $caseId: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCaseStatsFilterInput
  $limit: Int
  $nextToken: String
) {
  caseStatsByMeeting(
    meetingID: $meetingID
    caseId: $caseId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      meetingID
      caseId
      assetViews {
        items {
          id
          owner
          assetId
          userID
          timeWatched
          version
          createdAt
          updatedAt
          caseStatsAssetViewsId
          __typename
        }
        nextToken
        __typename
      }
      slides {
        items {
          id
          owner
          slideId
          time
          version
          createdAt
          updatedAt
          caseStatsSlidesId
          __typename
        }
        nextToken
        __typename
      }
      version
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CaseStatsByMeetingQueryVariables,
  APITypes.CaseStatsByMeetingQuery
>;
export const getCaseAssetView =
  /* GraphQL */ `query GetCaseAssetView($id: ID!) {
  getCaseAssetView(id: $id) {
    id
    owner
    assetId
    userID
    timeWatched
    version
    createdAt
    updatedAt
    caseStatsAssetViewsId
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetCaseAssetViewQueryVariables,
    APITypes.GetCaseAssetViewQuery
  >;
export const listCaseAssetViews = /* GraphQL */ `query ListCaseAssetViews(
  $filter: ModelCaseAssetViewFilterInput
  $limit: Int
  $nextToken: String
) {
  listCaseAssetViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      assetId
      userID
      timeWatched
      version
      createdAt
      updatedAt
      caseStatsAssetViewsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCaseAssetViewsQueryVariables,
  APITypes.ListCaseAssetViewsQuery
>;
export const getCaseSlideStats =
  /* GraphQL */ `query GetCaseSlideStats($id: ID!) {
  getCaseSlideStats(id: $id) {
    id
    owner
    slideId
    time
    version
    createdAt
    updatedAt
    caseStatsSlidesId
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetCaseSlideStatsQueryVariables,
    APITypes.GetCaseSlideStatsQuery
  >;
export const listCaseSlideStats = /* GraphQL */ `query ListCaseSlideStats(
  $filter: ModelCaseSlideStatsFilterInput
  $limit: Int
  $nextToken: String
) {
  listCaseSlideStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      slideId
      time
      version
      createdAt
      updatedAt
      caseStatsSlidesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCaseSlideStatsQueryVariables,
  APITypes.ListCaseSlideStatsQuery
>;
export const getMeetingNPSScore =
  /* GraphQL */ `query GetMeetingNPSScore($id: ID!) {
  getMeetingNPSScore(id: $id) {
    id
    owner
    userID
    user {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        startDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        currentAssetID
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          amountParticipants
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        streamUrl
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    meetingID
    scores
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetMeetingNPSScoreQueryVariables,
    APITypes.GetMeetingNPSScoreQuery
  >;
export const listMeetingNPSScores = /* GraphQL */ `query ListMeetingNPSScores(
  $filter: ModelMeetingNPSScoreFilterInput
  $limit: Int
  $nextToken: String
) {
  listMeetingNPSScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      userID
      user {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          startDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          currentAssetID
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          streamUrl
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      meetingID
      scores
      version
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingNPSScoresQueryVariables,
  APITypes.ListMeetingNPSScoresQuery
>;
export const npsScoresByUser = /* GraphQL */ `query NpsScoresByUser(
  $userID: ID!
  $meetingID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMeetingNPSScoreFilterInput
  $limit: Int
  $nextToken: String
) {
  npsScoresByUser(
    userID: $userID
    meetingID: $meetingID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      userID
      user {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          startDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          currentAssetID
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          streamUrl
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      meetingID
      scores
      version
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NpsScoresByUserQueryVariables,
  APITypes.NpsScoresByUserQuery
>;
export const npsScoresByMeeting = /* GraphQL */ `query NpsScoresByMeeting(
  $meetingID: ID!
  $userID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMeetingNPSScoreFilterInput
  $limit: Int
  $nextToken: String
) {
  npsScoresByMeeting(
    meetingID: $meetingID
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      userID
      user {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          startDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          currentAssetID
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          streamUrl
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      meetingID
      scores
      version
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NpsScoresByMeetingQueryVariables,
  APITypes.NpsScoresByMeetingQuery
>;
export const getAccessLink = /* GraphQL */ `query GetAccessLink($id: ID!) {
  getAccessLink(id: $id) {
    id
    purpose
    accessCode
    data
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccessLinkQueryVariables,
  APITypes.GetAccessLinkQuery
>;
export const listAccessLinks = /* GraphQL */ `query ListAccessLinks(
  $filter: ModelAccessLinkFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccessLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      purpose
      accessCode
      data
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccessLinksQueryVariables,
  APITypes.ListAccessLinksQuery
>;
