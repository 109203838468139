import LiveMeetingView from '@/pages/caseCluster/LiveMeetingView';
import { ROUTES } from '@/routes/Routes';
import { GetMeetingQuery, Meeting } from '@/services/API';
import { getMeeting } from '@/services/graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import { Navigate, useNavigate } from 'react-router-dom';
import GuestSignInForm from './GuestSignInForm';
import Cookies from 'js-cookie';
import { useUserAuthenticationContext } from '../userAuth/context/UserAuthenticationContext';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import MeetingStatsCreationContextProvider from '../meeting-stats/context/MeetingStatsCreationContext';
import CurrentMeetingDataContextProvider from './context/CurrentMeetingDataContext';
import { useEffect, useState } from 'react';
import MeetingNotStartedView from '@/pages/meeting/MeetingNotStartedView';
import { PageLoading } from '@/ui/PageLoading';
import MeetingFinishedView from '@/pages/meeting/MeetingFinishedView';
import { MEETING_STATUS } from './helpers/getMeetingStatus';
import EmptyPageWithTitle from '@/pages/page404/EmptyPageWithTitle.tsx';
import useMeetingStatusSubscription from './hooks/useMeetingStatusSubscription';

/*
This component is a wrapper for LiveMeetingView that checks if the user is allowed to enter
the meeting, wheter it's by guestUrlCode, or a UserAccess (in the future) or if he's part of a client
that has access to the meeting
It is also responsible for checking if the meeting has start or ended
*/
export default function LiveMeetingPermissions() {
  const navigate = useNavigate();

  const { isTemporaryUser, user, userClients, createUserIfNecessary } =
    useUserAuthenticationContext();

  const [isRunningChecks, setIsRunningChecks] = useState(true);
  //this is the meeting the user wants to join, but hasn't yet
  const [futureMeeting, setFutureMeeting] = useState<Meeting | undefined>(
    undefined
  );
  const [shouldAskForUsername, setShouldAskForUsername] = useState(false);
  const [isAskingForUsername, setIsAskingForUsername] = useState(false);
  const meetingID = new URLSearchParams(location.search).get('meeting');
  const guestUrlCode = new URLSearchParams(location.search).get('guestUrlCode');

  const meetingStatus = useMeetingStatusSubscription(meetingID);

  useEffect(() => {
    createUserIfNecessary();
  }, [createUserIfNecessary]);

  //get the details about the user's future meeting (he hasn't joined the meeting yet)
  useEffect(() => {
    if (!meetingID) return;
    const fetchFutureMeetingInfo = async () => {
      try {
        const meetingResponse = await callGraphQLApi<
          GraphQLResult<GetMeetingQuery>
        >(getMeeting, {
          id: meetingID,
        });
        setFutureMeeting(meetingResponse.data?.getMeeting as Meeting);
      } catch (error) {
        console.error(
          "LiveMeetingPermissions: couldn't get meeting info",
          error
        );
        console.log(meetingID);
      }
    };
    fetchFutureMeetingInfo();
  }, [meetingID, meetingStatus]);

  //checks if the user is allowed to enter the meeting and redirects him if he isn't
  useEffect(() => {
    if (!user || !futureMeeting) return;
    const runChecks = async () => {
      const isPartOfClientWithAccess = userClients?.some((client) =>
        futureMeeting.clientIDs?.includes(client.clientID)
      );

      //if he's part of a client that has access to the meeting, stop the function here, we won't need to redirect him, he can join the meeting
      if (isPartOfClientWithAccess) {
        // console.log(
        //   'live-meeting-permissions case 0: user is part of a client with access'
        // );
        return;
      }

      // if the user isn't logged in (detected by if he's temporary), and the user's url doesn't have a guest token, redirect to the home page
      if (isTemporaryUser && !guestUrlCode) {
        console.log(
          'live-meeting-permissions case 1: user not logged in, no guestUrlCode'
        );
        navigate(ROUTES.HOME, { replace: true });
        return;
      }

      //TODO: if the user doesn't have access to this meeting (UserMeetingAccess), redirect to the home page

      //if it's the wrong guestUrlCode, redirect to the home page
      if (
        futureMeeting &&
        guestUrlCode &&
        guestUrlCode !== futureMeeting.guestUrlCode
      ) {
        // console.log('live-meeting-permissions case 2: wrong guestUrlCode');
        navigate(ROUTES.HOME, { replace: true });
        return;
      }

      //if the user is a temporary one (not logged in), we need to ask him for his username
      if (futureMeeting && guestUrlCode && isTemporaryUser) {
        // console.log('guest-feature case 3: temporary user, ask for username');
        if (Cookies.get('guestUsername')) {
          // console.log('live-meeting-permissions case 3.1: already got username');
          return;
        }
        setShouldAskForUsername(true);
        setIsAskingForUsername(true);
        return;
      }
    };

    runChecks().finally(() => setIsRunningChecks(false));
  }, [user, futureMeeting, guestUrlCode, navigate, meetingID, userClients]);

  if (!meetingID)
    return <Navigate to={ROUTES.HOME} replace={true} state={{}} />;

  if (!futureMeeting) {
    return <PageLoading />;
  }

  /*
  meeting is not yet ready
  */
  if (meetingStatus === MEETING_STATUS.IDLE) {
    return (
      <MeetingNotStartedView openDate={new Date(futureMeeting.eventDate)} />
    );
  }

  /*
  meeting ready, but not yet started, display a waiting screen.
  */
  if (meetingStatus === MEETING_STATUS.READY) {
    return (
      <EmptyPageWithTitle
        title={futureMeeting.name}
        subtitle="This meeting has not started yet."
      />
    );
  }

  /*
   meeting is done
  */
  if (
    meetingStatus === MEETING_STATUS.ENDED ||
    meetingStatus === MEETING_STATUS.ARCHIVED
  ) {
    return <MeetingFinishedView />;
  }

  return (
    <>
      {shouldAskForUsername && (
        <GuestSignInForm
          isOpen={isAskingForUsername}
          setIsOpen={setIsAskingForUsername}
        />
      )}
      {
        //if we don't need to ask for username, or we are done asking for username, show the LiveMeetingView and we are done running checks
        (!shouldAskForUsername || !isAskingForUsername) && !isRunningChecks && (
          <CurrentMeetingDataContextProvider>
            <MeetingStatsCreationContextProvider>
              <LiveMeetingView />
            </MeetingStatsCreationContextProvider>
          </CurrentMeetingDataContextProvider>
        )
      }
    </>
  );
}
