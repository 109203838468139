import PageLayout from '@/ui/Layout/PageLayout';
import { Flex, Heading, Text } from '@chakra-ui/react';

interface Props {
  title: string;
  subtitle: string | string[];
}

export default function EmptyPageWithTitle({ title, subtitle }: Props) {
  return (
    <PageLayout>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        height="100%"
        px={8}
      >
        <Heading color="livelinx.purple200">{title}</Heading>
        {typeof subtitle === 'string' ? (
          <Text color="gray.700" fontSize="x-large">
            {subtitle}
          </Text>
        ) : (
          subtitle.map((line, index) => (
            <Text key={index} color="livelinx.purple200" fontSize="2rem">
              {line}
            </Text>
          ))
        )}
      </Flex>
    </PageLayout>
  );
}
