import {
  getMeetingStatus,
  getMeetingstatusColor,
  getMeetingStatusText,
} from '@/features/meeting/helpers/getMeetingStatus';
import { Meeting } from '@/services/API';
import { Tag } from '@chakra-ui/react';

type Props = {
  meeting: Meeting;
};

export const MeetingStatusTag = ({ meeting }: Props) => {
  const status = getMeetingStatus(meeting);
  return (
    <Tag
      ml={2}
      colorScheme={getMeetingstatusColor(status)}
      boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
    >
      {getMeetingStatusText(status)}
    </Tag>
  );
};
