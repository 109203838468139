import { SlideType } from '../enums';
import { ImageSlideType } from '../zod/slideTypes/imageSlideSchema';
import { Slide } from '../zod/slideTypes/slideSchema';

export default function isSlideImageSlide(
  slide: Slide
): slide is ImageSlideType {
  return slide.type === SlideType.IMAGE;
}

export function isSlideNotImageSlide(
  slide: Slide
): slide is Exclude<Slide, ImageSlideType> {
  return slide.type !== SlideType.IMAGE;
}
