import { Slide } from '@/utils/types/zod/slideTypes/slideSchema';
import { Flex } from '@chakra-ui/react';
import React from 'react';

type Props = {
  slide: Slide;
  children: React.ReactNode;
};

// layout helper
const calculateNumCols = (
  numItems: number,
  forcedColumns?: number | undefined
) => {
  // case forced
  if (forcedColumns) return forcedColumns;

  if (numItems === 1) return 1;
  if ([2, 4].includes(numItems)) return 2;
  if ([3, 5, 6].includes(numItems)) return 3;
  if ([7, 8].includes(numItems)) return 4;

  // max 3 row
  const minCol = Math.ceil(numItems / 3);
  return minCol;
};

function ItemContainer({ children, slide }: Props) {
  const numCols =
    slide && slide.items && calculateNumCols(slide.items.length, slide.numCols);

  const numRows = Math.ceil(slide.items.length / numCols);
  return (
    <Flex
      // height={`${60 / numRows}vh`} // TODO: we need to get rid of vh here.. it's messing everything
      height={`${100 / numRows}%`}
      width={`${90 / numCols}%`}
      alignItems="center"
      justifyContent="center"
      p="10px"
    >
      {children}
    </Flex>
  );
}

export default ItemContainer;
