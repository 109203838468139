import { Box, Image, Text } from '@chakra-ui/react';
import mainLLXLogo from '@/assets/images/logo_main.png';
import useDeviceSize from '@/services/hooks/useDeviceSize';
import { Link } from 'react-router-dom';
import { ROUTES } from '@/routes/Routes';

type Props = {
  date: number;
  location: string;
  backLink?: string;
};

export default function Header({ location, date }: Props) {
  const { isDesktopOrLaptop, isMobile } = useDeviceSize();
  return (
    <Box
      display="flex"
      flexDirection={isDesktopOrLaptop ? 'row' : 'column'}
      alignItems="center"
      justifyContent="space-between"
    >
      <Link to={ROUTES.HOME}>
        <Image
          src={mainLLXLogo}
          width="150px"
          aspectRatio="250:170"
          marginLeft={isDesktopOrLaptop ? '40px' : ''}
          marginTop="25px"
        />
      </Link>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="center"
        marginRight={isDesktopOrLaptop ? '50px' : ''}
        marginTop={isDesktopOrLaptop ? '' : '25px'}
        marginBottom={isDesktopOrLaptop ? '' : '10px'}
        maxW={isDesktopOrLaptop ? '600px' : '75%'}
      >
        <Text
          variant="purpleTitleBold"
          marginRight="1rem"
          fontSize="20px"
          textAlign="center"
        >
          Session Report
        </Text>
        <Text variant="purpleTitle" fontSize="20px">
          {`${new Date(date).toDateString()}, ${location}`}
        </Text>
      </Box>
    </Box>
  );
}
