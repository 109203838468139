import Table from '@/ui/Table';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Column } from 'react-table';

type Props<T extends object> = {
  columns: Column<T>[];
  data: T[];
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

export default function ScoreDetailModal<T extends object>({
  isOpen,
  onClose,
  data,
  columns,
  children,
}: Props<T>) {
  // const columns: Column<IterableLLXScore>[] = ;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="800px">
        <ModalHeader>Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table columns={columns} data={data} size="sm" />
          {children}
        </ModalBody>

        <ModalFooter>
          <Button variant="primary" onClick={onClose}>
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
