import PageLayout from '@/ui/Layout/PageLayout';
import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import clearAllMockingData from '@/features/meeting-stats/mocking-tool/general/clearAllMockingData';
import { startMock } from '@/features/meeting-stats/mocking-tool';
import {
  ListMeetingsQuery,
  ListMeetingsQueryVariables,
  ListMeetingStatsQuery,
  ListMeetingStatsQueryVariables,
  MeetingStats,
  UpdateMeetingStatsMutationVariables,
} from '@/services/API';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import { listMeetings, listMeetingStats } from '@/services/graphql/queries';
import { migrateMeetingStatsV102 } from '@/backend/migrations/meetingStatsMigrations';
import { updateMeetingStats } from '@/services/graphql/mutations';
import { mutationDeleteCaseStats } from '@/features/meeting-stats/graphql/delete';

async function handleMeetingStatsMigration() {
  const variables: ListMeetingStatsQueryVariables = {};
  const meetingStatsResponse = await callGraphQLApi<
    GraphQLResult<ListMeetingStatsQuery>
  >(listMeetingStats, variables);
  const meetingStats = meetingStatsResponse.data?.listMeetingStats
    ?.items as MeetingStats[];

  if (!meetingStats) {
    console.error('No meeting stats found');
    return;
  }
  console.log('DEBUG >> amt meeting stats', meetingStats.length);
  await Promise.all(
    meetingStats.map(async (meetingStat) => {
      console.log('DEBUG >> ', meetingStat.version);
      const migratedMeetingStat = await migrateMeetingStatsV102(meetingStat);
      if (migratedMeetingStat) {
        const variables: UpdateMeetingStatsMutationVariables = {
          input: {
            id: meetingStat.id,
            version: migratedMeetingStat.version,
            casePresentationHistory:
              migratedMeetingStat.casePresentationHistory,
          },
        };
        console.log(
          'DEBUG >> needs update',
          meetingStat.casePresentationHistory,
          migratedMeetingStat.casePresentationHistory
        );
        await callGraphQLApi(updateMeetingStats, variables);
      }
    })
  );

  console.log('done migrating meeting stats');
}

async function handleRemoveCaseStatsNotPartOfCaseCluster() {
  const variables: ListMeetingsQueryVariables = {};
  const meetingsResponse = await callGraphQLApi<
    GraphQLResult<ListMeetingsQuery>
  >(listMeetings, variables);
  const meetings = meetingsResponse.data?.listMeetings?.items || [];
  console.log('DEBUG >> amt meetings', meetings.length);
  await Promise.all(
    meetings.map(async (meeting) => {
      if (!meeting) return;
      const meetingStats = meeting.caseStats?.items || [];
      const caseIds = meeting.caseCluster?.cases || [];

      if (caseIds.length === 0 || meetingStats.length === 0) {
        console.warn(
          'DEBUG >> caseIds or meetingStats is empty, skipping this meeting, ',
          meeting.id,
          caseIds,
          meetingStats
        );
        return;
      }
      await Promise.all(
        meetingStats.map(async (meetingStat) => {
          if (!meetingStat) {
            console.warn('DEBUG >> meetingStat is empty, skipping', meeting.id);
            return;
          }
          const found = caseIds.find(
            (caseId) => caseId === meetingStat?.caseId
          );
          if (!found) {
            console.log(
              'DEBUG >> needs change',
              meeting.id,
              meetingStat?.caseId
            );
            await mutationDeleteCaseStats(meetingStat.id);
          }
        })
      );
    })
  );
  console.log('DEBUG >> done removing caseStats not part of caseCluster');
}

function AdminTestPage() {
  return (
    <PageLayout>
      <Flex alignItems="center" justifyContent="center" gap="20px">
        <VStack maxWidth="300px">
          <Text fontSize="30px" color="livelinx.purple200">
            Meeting mocking
          </Text>
          <Button
            width="full"
            variant="primary"
            onClick={() => startMock(true)}
          >
            Create a new mock meeting
          </Button>

          <Button width="full" variant="primary" onClick={clearAllMockingData}>
            Delete mocking data
          </Button>

          <Button
            width="full"
            variant="primary"
            onClick={handleMeetingStatsMigration}
          >
            Migrate meeting stats
          </Button>
          <Button
            width="full"
            variant="primary"
            onClick={() => {
              handleRemoveCaseStatsNotPartOfCaseCluster();
            }}
          >
            Remove caseStats not part of the casecluster
          </Button>
        </VStack>
      </Flex>
    </PageLayout>
  );
}

export default AdminTestPage;
