import { SlideItemType } from '@/utils/types/enums';
import { z } from 'zod';

export const slideShowItemSchema = z.object({
  type: z.literal(SlideItemType.SLIDE_SHOW_ITEM),

  slideRef: z.string(),
});

export type SlideShowItemType = z.infer<typeof slideShowItemSchema>;
