import { CaseAssetView } from '@/services/API';
import { Asset } from '@/utils/types/zod/assetSchema';

export type ParsedAssetViews = Asset & {
  views: number;
  viewDistribution: Map<string, number>;
  timeDistribution: Map<string, number>;
};

export type ParsedAssetViewsPerCase = Record<string, ParsedAssetViews[]>;

export function parseAssetViewsPerCase(
  cases: string[],
  caseIdToAssetList: Record<string, Asset[]>,
  caseIdToAssetViews: Record<string, CaseAssetView[]>
): ParsedAssetViewsPerCase {
  const assetViewsPerCase: ParsedAssetViewsPerCase = {};

  for (const c of cases) {
    const assetViews = caseIdToAssetViews[c];
    const assetIdList = caseIdToAssetList[c];
    const arrayParsedAssets = parseAssetViews(assetIdList, assetViews);
    assetViewsPerCase[c] = arrayParsedAssets;
  }
  return assetViewsPerCase;
}

function parseAssetViews(
  assetList: Asset[],
  assetViews: CaseAssetView[]
): ParsedAssetViews[] {
  const arrayParsedAssets: ParsedAssetViews[] = [];
  for (const asset of assetList) {
    const newParsedAssets = {
      ...asset,
      views: assetViews.reduce((acc, assetView) => {
        if (assetView.assetId === asset.id) {
          return acc + 1;
        }
        return acc;
      }, 0),
      viewDistribution: assetViews.reduce((acc, assetView) => {
        if (assetView.assetId === asset.id) {
          const { userID } = assetView;
          const currValue = acc.get(userID) ?? 0;
          acc.set(userID, currValue + 1);
        }
        return acc;
      }, new Map<string, number>()),
      timeDistribution: assetViews.reduce((acc, assetView) => {
        if (assetView.assetId === asset.id) {
          const { userID, timeWatched } = assetView;
          const currValue = acc.get(userID) ?? 0;
          acc.set(userID, currValue + timeWatched);
        }
        return acc;
      }, new Map<string, number>()),
    };
    arrayParsedAssets.push(newParsedAssets);
  }

  //sort by views
  arrayParsedAssets.sort((a, b) => b.views - a.views);
  return arrayParsedAssets;
}
