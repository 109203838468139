import { Button, Flex } from '@chakra-ui/react';
import CaseSlide, { CaseSlideProps } from '../CaseSlide';
import useSlideVariations from '../hooks/useSlideVariation';
import { Slide } from '@/utils/types/zod/slideTypes/slideSchema';

type Props = {
  subSlides: Slide[];
  currentSubSlide: Slide;
  caseSlideProps: CaseSlideProps;
  onChangeSubSlide: (index: number) => void;
  variationDetails: ReturnType<typeof useSlideVariations> | null;
};

export default function SlideShowSlideView({
  subSlides,
  currentSubSlide,
  caseSlideProps,
  onChangeSubSlide,
  variationDetails,
}: Props) {
  return (
    <Flex flexDir="column" w="full" h="full">
      <CaseSlide
        {...caseSlideProps}
        slide={currentSubSlide}
        variationDetails={variationDetails}
        hideSlideID
      />

      <Flex gap={2}>
        {subSlides.map((subSlide, index) => {
          return (
            <>
              <Button key={subSlide.id} onClick={() => onChangeSubSlide(index)}>
                {index + 1}
              </Button>
            </>
          );
        })}
      </Flex>
    </Flex>
  );
}
