import { CaseMap } from '@/features/caseCluster/caseCluster.types';
import {
  CaseSlideStats,
  CaseStats,
  Meeting,
  OnUpdateCaseSlideStatsSubscription,
} from '@/services/API';
import { onUpdateCaseSlideStats } from '@/services/graphql/subscriptions';
import useNewSubscriptions from '@/services/subscriptions/useNewSubscriptions';
import { Slide } from '@/utils/types/zod/slideTypes/slideSchema';
import { UseQueryResult } from '@tanstack/react-query';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { getCaseIdFromCaseStatsList } from '../utils/getCaseIdFromCaseStatsList';
import { parseSlideStatsPerCase } from '../utils/parseSlideStatsPerCase';
import isRecordEmpty from '@/utils/helpers/isRecordEmpty';
import { captureSentry } from '@/utils/helpers/sentryHelper';

export default function useMeetingSlideStats(
  activated: boolean,
  meetingID: string | undefined,
  meetingDetails: UseQueryResult<Meeting, unknown>,
  isCaseLoading: boolean,
  caseMap: CaseMap,
  caseIds: string[]
) {
  const [slideStatsPerCase, setSlideStatsPerCase] = useState<
    Record<string, CaseSlideStats[]>
  >({});

  const onCaseSlideStatsUpdate = useCallback(
    (data: OnUpdateCaseSlideStatsSubscription) => {
      console.log('onUpdateCaseSlideStats', data);
      const caseId = getCaseIdFromCaseStatsList(
        data.onUpdateCaseSlideStats?.caseStatsSlidesId as string,
        meetingDetails.data?.caseStats?.items as CaseStats[]
      );
      if (
        !meetingDetails.data?.caseCluster?.cases.find((el) => el === caseId)
      ) {
        captureSentry({
          title:
            'useMeetingSlideStats | onUpdateCaseSlideStats caseId not found in caseCluster',
          detail: {
            caseId,
            caseClusterCases: meetingDetails.data?.caseCluster?.cases,
          },
        });
      }
      if (!caseId) {
        console.warn(
          'Could not find caseId for the detected slide stats update (this is probably data from another meeting)'
        );
        return;
      }
      console.log('Updating slide stats list');
      setSlideStatsPerCase((prev) => {
        const newObj: Record<string, CaseSlideStats[]> = {
          ...prev,
        };
        //remove the existing slide
        if (!newObj[caseId]) {
          return prev;
        }
        const indexToRemove = newObj[caseId].findIndex(
          (item) => item?.id === data.onUpdateCaseSlideStats?.id
        );
        if (indexToRemove >= 0) {
          newObj[caseId].splice(indexToRemove, 1);
        } else {
          console.warn(
            'useMeetingSlideStats: indexToRemove null. This means that the updated slide was not in the list of slides which is not normal but not dangerous.',
            data.onUpdateCaseSlideStats?.slideId,
            data.onUpdateCaseSlideStats?.id
          );
          console.log(newObj[caseId]);
        }
        //add the new slide info
        newObj[caseId].push(data.onUpdateCaseSlideStats as CaseSlideStats);
        return newObj;
      });
    },
    [meetingDetails.data]
  );

  const parsedSlideStats = useMemo(() => {
    //we don't want to continue if anything needed is still loading
    if (
      isCaseLoading ||
      !meetingDetails.data?.caseStats?.items ||
      !caseMap ||
      !activated ||
      Object.keys(slideStatsPerCase).length !== caseIds.length
    ) {
      return undefined;
    }
    // console.log('Recomputing parsedSlideStats');
    const caseIdToSlideList: Record<string, Slide[]> = {};
    for (const caseId of caseIds) {
      caseIdToSlideList[caseId] = caseMap[caseId].slides || [];
    }
    return parseSlideStatsPerCase(
      caseIds,
      caseIdToSlideList,
      slideStatsPerCase
    );
  }, [caseIds, slideStatsPerCase, isCaseLoading, activated]);

  const subscription = useMemo(() => {
    if (!meetingID || !activated) return [];
    return [
      {
        query: onUpdateCaseSlideStats,
        variables: {},
        callback: onCaseSlideStatsUpdate,
      },
    ];
  }, [meetingID, onCaseSlideStatsUpdate, activated]);

  useNewSubscriptions(subscription, 'useMeetingSlideStats');

  //populate the slides for the first time
  useEffect(() => {
    if (
      !meetingDetails.data?.caseStats?.items ||
      isCaseLoading ||
      Object.keys(caseMap).length === 0 ||
      !activated
    )
      return;
    console.log('DEBUG >> started useMeetingSlideStats calcs');
    const newSlideStats =
      meetingDetails.data?.caseStats?.items.reduce((acc, cur) => {
        if (!cur || !cur.slides?.items) return acc;
        const caseId = cur.caseId;
        if (!caseId) return acc;

        if (!caseMap || isRecordEmpty(caseMap)) {
          //if we haven't loaded the casemap yet, we can't filter the slides
          return acc;
        }
        if (caseId in caseMap === false) {
          captureSentry({
            title:
              'useMeetingSlideStats | meeting has a caseStats object of a case not part of the caseCluster',
            detail: {
              caseId,
              caseMap,
              caseStats: meetingDetails.data?.caseStats?.items,
              caseClusterID: meetingDetails.data.caseClusterID,
              caseClusterName: meetingDetails.data.caseCluster?.name,
              caseClusterCases:
                meetingDetails.data.caseCluster?.cases.join(', '),
            },
          });
          return acc;
        }
        // getting the saved stats and removing the stats about deleted slides (old slides)
        acc[caseId] = cur.slides.items as CaseSlideStats[];
        acc[caseId] = acc[caseId].filter((item) => {
          if (!item) return false;
          const slideInfo = caseMap[caseId].slides?.find(
            (slide) => slide.id === item.slideId
          );
          if (!slideInfo) return false;
          return true;
        });
        return acc;
      }, {} as Record<string, CaseSlideStats[]>) ?? {};
    setSlideStatsPerCase(newSlideStats);
  }, [
    meetingDetails.data?.caseStats?.items,
    caseMap,
    isCaseLoading,
    activated,
  ]);

  return {
    parsedSlideStats,
  };
}
