import {
  GetMeetingQuery,
  Meeting,
  OnUpdateMeetingSubscription,
} from '@/services/API';
import { onUpdateMeeting } from '@/services/graphql/subscriptions';
import useNewSubscriptions from '@/services/subscriptions/useNewSubscriptions';

import { useMemo, useState } from 'react';
import { MEETING_STATUS, getMeetingStatus } from '../helpers/getMeetingStatus';
import { useQuery } from '@tanstack/react-query';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import { getMeeting } from '@/services/graphql/queries';

export default function useMeetingStatusSubscription(
  meetingID?: string | null
) {
  const [meetingStatus, setMeetingStatus] = useState<MEETING_STATUS | null>(
    null
  );
  // initial fetch
  useQuery({
    queryKey: ['meeting status'],
    refetchOnWindowFocus: false,
    enabled: !!meetingID,
    queryFn: async () => {
      if (!meetingID) return null;
      const meetingResponse = await callGraphQLApi<
        GraphQLResult<GetMeetingQuery>
      >(getMeeting, {
        id: meetingID,
      });
      return meetingResponse.data?.getMeeting as Meeting;
    },
    onSuccess: (data: Meeting) => {
      if (!data) return;
      setMeetingStatus(getMeetingStatus(data));
    },
  });

  // subscription
  const subscriptions = useMemo(() => {
    return [
      {
        query: onUpdateMeeting,
        variables: {},
        callback: (data: OnUpdateMeetingSubscription) => {
          if (data.onUpdateMeeting?.id === meetingID) {
            setMeetingStatus(getMeetingStatus(data.onUpdateMeeting as Meeting));
          }
        },
      },
    ];
  }, [meetingID]);

  useNewSubscriptions(subscriptions, 'useMeetingStatusSubscription');

  return meetingStatus;
}
