import { Slide } from '@/utils/types/zod/slideTypes/slideSchema';
import { create } from 'zustand';

type CurrentSlideDataStore = {
  currentSlide: Slide | null;
  //NOTE: If the currentSlide has a variation, this will be the variation slide
  currentSlideWithVariation: Slide | null;
  // title: string;
  // slideID: string;

  // setTitle: (title: string) => void;
  // setSlideID: (slideID: string) => void;

  setCurrentSlide: (slide: Slide) => void;
  setCurrentSlideWithVariation: (slide: Slide | null) => void;
};

export const useCurrentSlideData = create<CurrentSlideDataStore>((set) => ({
  currentSlide: null,
  currentSlideWithVariation: null,

  setCurrentSlide: (slide: Slide) => set({ currentSlide: slide }),
  setCurrentSlideWithVariation: (slide: Slide | null) =>
    set({ currentSlideWithVariation: slide }),
  // title: '',
  // slideID: '',

  // setTitle: (title: string) => set({ title }),
  // setSlideID: (slideID: string) => set({ slideID }),
}));
