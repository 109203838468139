import {
  composeQuestionID,
  mutationUpdateOrCreateAnswer,
} from '@/features/meeting/answer/answerHelper';
import getSlideVotingStats, {
  SlideVotingStats,
} from '@/features/meeting/answer/getSlideVotingStats';
import { Answer, Meeting } from '@/services/API';
import { UseAnswersBySlideReturnType } from '@/services/hooks/useAnswersBySlide';
import { SlideType } from '@/utils/types/enums';
import isSlideItemSelectableAnswerItem from '@/utils/types/type-validation/isSlideItemSelectableAnswerItem';
import isSlideItemTextAnswerItem from '@/utils/types/type-validation/isSlideItemTextAnswerItem';
import { QuestionSlideType } from '@/utils/types/zod/slideTypes/questionSlideSchema';
import { SelectableAnswerSlideItemType } from '@/utils/types/zod/slideTypes/slideItemTypes/answerSlideItemTypes/selectableAnswerSlideItemSchema';
import { TextAnswerSlideItemType } from '@/utils/types/zod/slideTypes/slideItemTypes/answerSlideItemTypes/textAnswerSlideItemSchema';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { answerGlowDuration } from '../QuestionSlide';
import { CustomSlideType } from '@/utils/types/zod/slideTypes/customSlideSchema';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import { PatientCase } from '@/utils/types/zod/patientCaseSchema';

export type QuestionContextProps = {
  slide: QuestionSlideType | CustomSlideType;
  userID: string | undefined;
  meeting: Meeting | undefined;
  currentCase: PatientCase;
  isUserHost: boolean;
  answersForSlideDetails: UseAnswersBySlideReturnType;

  children: React.ReactNode;
};

type TextChangeEvent = {
  answerID: string;
  text: string;
};

type QuestionContextType = {
  answers: Answer[];
  // userAnswer: Answer | undefined;
  currentSelectedAnswers: string[];
  currentTextAnswers: string[];
  isAnswerConfirmed: boolean;
  // setAnswerConfirmed: (isConfirmed: boolean) => void;
  hostStats: SlideVotingStats;
  onSelectableAnswerClick: (answerID: string) => void;
  onTextAnswerChange: (events: TextChangeEvent[]) => void;
  onConfirmAnswer: () => void;
  glowingVoteItem: SelectableAnswerSlideItemType | undefined;
  hasAtleastSelectedOneAnswer: boolean;
  slideHasAtLeastOneSelectableAnswer: boolean;
  requestTextAnswerIndex: (answerID: string) => number;
  slideHasVoteItems: boolean;
  isSaving: boolean;
};

export const QuestionContext = createContext<QuestionContextType | undefined>(
  undefined
);

export default function QuestionContextProvider({
  children,
  slide,
  userID,
  meeting,
  currentCase,
  isUserHost,
  answersForSlideDetails,
}: QuestionContextProps) {
  const voteItems: SelectableAnswerSlideItemType[] = useMemo(
    () => slide.items.filter(isSlideItemSelectableAnswerItem),
    [slide.items]
  );
  const textItems: TextAnswerSlideItemType[] = useMemo(
    () => slide.items.filter(isSlideItemTextAnswerItem),
    [slide.items]
  );
  const slideHasVoteItems = voteItems.length > 0;

  const [saveTrigger, triggerSaveToDatabase] = useState<number>(0);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isAnswerConfirmed, setAnswerConfirmed] = useState<boolean>(false);
  const [currentSelectedAnswers, setCurrentSelectedAnswers] = useState<
    string[]
  >([]);
  const [currentTextAnswers, setCurrentTextAnswers] = useState<string[]>(
    textItems.map(() => '')
  );
  const hostStats = useMemo(
    () => getSlideVotingStats(slide.items, answersForSlideDetails.answers),
    [slide.id, answersForSlideDetails.answers]
  );

  const [glowingVoteItem, setGlowingVoteItem] = useState<
    SelectableAnswerSlideItemType | undefined
  >();

  const slideHasAtLeastOneSelectableAnswer = voteItems.length > 0;
  //NOTE: we're checking that there is at least one answer to select and that the user has selected at least one answer
  const hasAtleastSelectedOneAnswer =
    slideHasAtLeastOneSelectableAnswer && currentSelectedAnswers.length > 0;

  const saveToDatabase = async () => {
    if (isSaving) {
      captureSentry({
        title:
          'saving to database some datas, but saving is still in progress!',
      });
      return; // security
    }

    if (!meeting || !currentCase || !userID) {
      captureSentry({
        title: 'Can not save answer to database',
        detail: {
          meeting,
          currentCase,
          userID,
        },
      });
      return;
    }
    console.log('this is what is being saved', currentTextAnswers);
    setIsSaving(true);
    await mutationUpdateOrCreateAnswer(
      userID,
      meeting.id,
      composeQuestionID(meeting.id, currentCase.id, slide.id),
      currentSelectedAnswers,
      currentTextAnswers
    );
    setIsSaving(false);
  };

  const requestTextAnswerIndex = (answerID: string) => {
    const answerItem = textItems.find((item) => item.id === answerID);
    if (!answerItem) {
      captureSentry({
        title: 'QuestionContext: Answer item not found',
        detail: {
          answerID,
          textItems,
        },
      });
      throw new Error('QuestionContext: Answer item not found');
    }
    const itemIndex = textItems.indexOf(answerItem);
    return itemIndex;
  };

  const onSelectableAnswerClick = (answerID: string) => {
    if (slide.type === SlideType.QUESTION_SINGLE) {
      setCurrentSelectedAnswers([answerID]);
      return;
    }
    if (slide.type === SlideType.QUESTION_MULTIPLE) {
      //if it's already selected, we remove it
      if (currentSelectedAnswers.includes(answerID)) {
        setCurrentSelectedAnswers((prev) =>
          prev.filter((index) => index !== answerID)
        );
        return;
      }
      //if it's not selected, we add it
      if (currentSelectedAnswers.length < slide.maxChoices)
        setCurrentSelectedAnswers((prev) => [...prev, answerID]);
    }
    if (slide.type === SlideType.QUESTION_MULTIPLE_ORDERED) {
      //if it's already selected, we remove it and remove all the following answers
      if (currentSelectedAnswers.includes(answerID)) {
        setCurrentSelectedAnswers((prev) => {
          const indexInArray = prev.indexOf(answerID);
          return prev.filter((_, idx) => idx < indexInArray);
        });
        return;
      }
      //if it's not selected, we add it
      if (currentSelectedAnswers.length < slide.correctOrderIds.length)
        setCurrentSelectedAnswers((prev) => [...prev, answerID]);
    }
  };

  const onTextAnswerChange = (events: TextChangeEvent[]) => {
    events.forEach((event) => {
      const itemIndex = requestTextAnswerIndex(event.answerID);
      setCurrentTextAnswers((prev) => {
        const newTextAnswers = [...prev];
        newTextAnswers[itemIndex] = event.text;
        return newTextAnswers;
      });
    });
    triggerSaveToDatabase(Date.now());
  };

  const onConfirmAnswer = () => {
    setAnswerConfirmed(true);
    triggerSaveToDatabase(Date.now());
  };

  //NOTE: This is for the background glow of the answers when we haven't answered yet
  useEffect(() => {
    if (
      isAnswerConfirmed ||
      isUserHost ||
      hasAtleastSelectedOneAnswer ||
      voteItems.length <= 0
    )
      return;

    const interval = window.setInterval(() => {
      if (!glowingVoteItem) {
        setGlowingVoteItem(voteItems[0]);
        return;
      }
      const index = voteItems.indexOf(glowingVoteItem);
      setGlowingVoteItem(voteItems[(index + 1) % voteItems.length]);
    }, answerGlowDuration);

    return () => {
      if (interval) window.clearInterval(interval);
    };
  }, [
    isAnswerConfirmed,
    isUserHost,
    voteItems,
    hasAtleastSelectedOneAnswer,
    glowingVoteItem,
  ]);

  //when the user answer changes, we update the current selected answers (in case the user reload for example)
  useEffect(() => {
    if (
      !answersForSlideDetails.userAnswer ||
      //NOTE: This makes sure that the answers are for the current slide
      answersForSlideDetails.userAnswer.questionID.includes(slide.id) === false
    )
      return;
    setCurrentSelectedAnswers(answersForSlideDetails.userAnswer.answerIndexes);
    setAnswerConfirmed(
      answersForSlideDetails.userAnswer.answerIndexes.length > 0
    );
    setCurrentTextAnswers(answersForSlideDetails.userAnswer.answerTexts);
  }, [answersForSlideDetails.userAnswer]);

  useEffect(() => {
    if (saveTrigger === 0) return;
    console.log('user answer has been updated');
    saveToDatabase();
  }, [saveTrigger]);

  return (
    <QuestionContext.Provider
      value={{
        answers: answersForSlideDetails.answers,
        // userAnswer,
        currentSelectedAnswers,
        currentTextAnswers,
        isAnswerConfirmed,
        // setAnswerConfirmed,
        hostStats,
        onSelectableAnswerClick,
        onTextAnswerChange,
        onConfirmAnswer,
        glowingVoteItem,
        hasAtleastSelectedOneAnswer,
        slideHasAtLeastOneSelectableAnswer,
        requestTextAnswerIndex,
        slideHasVoteItems,
        isSaving,
      }}
    >
      {children}
    </QuestionContext.Provider>
  );
}

export const useQuestionContext = () => {
  const context = useContext(QuestionContext);
  if (context === undefined) {
    throw new Error(
      'useQuestionContext must be used within a QuestionContextProvider'
    );
  }
  return context;
};
