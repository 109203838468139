import { trackPageView } from './trackingHelpers';
import { trackEvent, trackData } from '@/features/tracking/trackingHelpers';
import { useCallback, useState } from 'react';
import { CustomSlideIndex } from '@/utils/types/enums';
import { useCurrentMeetingDataContext } from '../meeting/context/CurrentMeetingDataContext';
import { mutationCreateNPSScoreIfNotExists } from '../meeting-stats/graphql/create';
import { mutationUpdateLeaveUserMeetingJoin } from '../meeting/graphql/mutationUpdateLeaveUserMeetingJoin';
import { captureSentry } from '@/utils/helpers/sentryHelper';

const useMeetingTracking = (isPreview: boolean) => {
  const {
    meeting,
    caseMap,
    caseClusterName,
    currentCaseId,
    currentCaseIndex,
    slideIndex,
    slideName,
    user,
    isUserHost,
  } = useCurrentMeetingDataContext();

  const meetingName = meeting?.name;
  const hostId = meeting?.hostID;
  const userId = user?.id;

  const [currentMeetingTracked, setCurrentTrackedMeeting] = useState<
    string | undefined
  >();

  // --------------------- methods ------------------------

  const getNumMeetingSlides = () => {
    let numMeetingSlides = 0;
    Object.values(caseMap).forEach((item) => {
      if (item.slides) {
        numMeetingSlides += item.slides.length;
      }
    });
    return numMeetingSlides;
  };
  const getNumMeetingAssets = () => {
    let numMeetingAssets = 0;
    Object.values(caseMap).forEach((item) => {
      if (item.assets) {
        numMeetingAssets += item.assets.length;
      }
    });
    return numMeetingAssets;
  };

  const trackSlideChange = () => {
    if (isPreview) return; // do not track if preview mode
    if (!caseClusterName) return; // do not track if caseCluster is not loaded.

    let pageUrl;
    let pageTitle;

    if (!meeting?.currentCase) {
      pageUrl = `${meetingName}/00_case_selector`;
      pageTitle = `${caseClusterName}/case_selector`;
    } else if (slideIndex === CustomSlideIndex.NPS_SCORE) {
      pageUrl = `${meetingName}/999_NPS_score`;
      pageTitle = `${caseClusterName}/NPS_score`;
      // track caseCluster completed
      if (!isUserHost) {
        trackCaseClusterCompleted();
      }
    } else {
      const customSlideIndex =
        slideIndex === 0
          ? '00_patient_profile'
          : slideIndex?.toString().padStart(2, '0');
      pageUrl = `${meetingName}/${(currentCaseIndex + 1)
        .toString()
        .padStart(2, '0')}_${currentCaseId}/slide_${customSlideIndex}`;
      // pageTitle = `${caseClusterName}/${currentCaseId}/${
      //   slideName || customSlideIndex
      // }`;
      pageTitle = slideName || `slide_${customSlideIndex}`;
    }

    trackPageView({
      pageUrl,
      pageTitle,
      userType: isUserHost ? 'Host' : 'User',
      userId: userId || '',
      hostId: hostId,
      meetingId: meeting?.id,
      meetingName: meeting?.name,
      caseClusterName: caseClusterName,
      patientCase: currentCaseId ?? 'none', // TODO: here we need to track the case selector also!!! so we can see difference between "none" and "case selector"
    });

    // once first trackPageView of a meeting is set, we can actually send a MeetingJoin event
    if (!currentMeetingTracked) {
      if (isUserHost) trackCaseClusterStart();
      else trackMeetingJoin();
      setCurrentTrackedMeeting(meetingName);
    }
  };

  /*
  Depreciated: this is not used anymore but not deleted in the code because maybe we'll use it again ?
  */
  const trackAnswer = useCallback(
    (answerIndex: number, isConfirmed: boolean) => {
      if (isPreview) return; // do not track if preview mode
      trackEvent('Answer', isConfirmed ? 'Submit' : 'Select', `${answerIndex}`);
    },
    []
  );

  const trackMeetingLeave = useCallback(() => {
    if (!meeting) {
      captureSentry({
        title: 'trackMeetingLeave: meeting is undefined',
        detail: {
          meeting,
          userId,
        },
      });
      return;
    }
    if (!userId) {
      captureSentry({
        title: 'trackMeetingLeave: userId is undefined',
        detail: {
          userId,
          meeting,
        },
      });
      return;
    }
    // ReactGA.set(getTrackingDetails());
    trackEvent('meeting', 'leave');
    setCurrentTrackedMeeting(undefined);

    mutationUpdateLeaveUserMeetingJoin(userId, meeting.id, false);
  }, [meeting?.id, userId]);

  const trackConsensusRate = useCallback((consensusRate: number) => {
    if (isPreview) return; // do not track if preview mode
    trackData({
      voteConsensus: consensusRate,
    });
  }, []);

  const trackMeetingJoin = useCallback(() => {
    if (isPreview) return; // do not track if preview mode
    trackEvent('meeting', 'join');
  }, []);

  const trackCaseClusterStart = () => {
    if (isPreview) return; // do not track if preview mode
    trackEvent('CaseCluster', 'Start', '', {
      caseIndex: currentCaseIndex + 1,
      meeting_slides: getNumMeetingSlides(),
      meeting_assets: getNumMeetingAssets(),
    });
  };

  const trackCaseClusterStop = useCallback(() => {
    if (isPreview) return; // do not track if preview mode
    trackEvent('CaseCluster', 'Stop');
  }, []);

  const trackCaseClusterCompleted = useCallback(() => {
    if (isPreview) return; // do not track if preview mode
    trackEvent('CaseCluster', 'Completed');
  }, []);

  const createNPSScoreResult = useCallback(
    (meetingID: string, userID: string, score: number[]) => {
      if (meetingID !== meeting?.id) {
        captureSentry({
          title: "createNPSScoreResult: meetingID doesn't match",
          detail: {
            meetingID,
            meeting,
            userID,
            score,
          },
        });
        return new Promise<void>((resolve) => {
          resolve();
        });
      }
      // do not track if preview mode or sandbox mode
      if (isPreview || meeting.isInTesting)
        return new Promise<void>((resolve) => {
          resolve();
        });
      return mutationCreateNPSScoreIfNotExists(meetingID, userID, score);
    },
    [meeting]
  );

  // --------------------- return ------------------------

  return {
    trackMeetingLeave,
    trackCaseClusterStop,
    trackAnswer,
    trackSlideChange,
    trackConsensusRate,
    createNPSScoreResult,
  };
};

export default useMeetingTracking;
