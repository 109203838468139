import { z } from 'zod';
import { anamneseSlideSchema } from './anamneseSlideSchema';
import { customSlideSchema } from './customSlideSchema';
import { infoSlideSchema } from './infoSlideSchema';
import { questionSlideSchema } from './questionSlideSchema';
import { imageSlideSchema } from './imageSlideSchema';
import { slideShowSlideSchema } from './slideShowSlideSchema';

export const slideSchema = z.union([
  infoSlideSchema,
  questionSlideSchema,
  anamneseSlideSchema,
  imageSlideSchema,
  slideShowSlideSchema,
  customSlideSchema,
]);

export type Slide = z.infer<typeof slideSchema>;
