import getAvailableUserMeetings from '@/features/meeting/helpers/getAvailableUserMeetings';
import { MeetingSelectors } from '@/store/slices/meeting';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/Routes';
import { useTranslation } from 'react-i18next';
import { trackPageView } from '@/features/tracking/trackingHelpers';
import MeetingList from '@/components/MeetingList/MeetingList';
import {
  Box,
  Center,
  Fade,
  Flex,
  Heading,
  Button,
  ButtonProps,
} from '@chakra-ui/react';
import { useUserMeetingPing } from '@/services/hooks/useUserMeetingPing';
import { Meeting } from '@/services/API';
import { fetchMeetingList } from '@/store/thunk/meeting';
import { useAppDispatch, useAppSelector } from '@/store/StoreHooks';
import PageLayout from '@/ui/Layout/PageLayout';
import { useUserAuthenticationContext } from '../userAuth/context/UserAuthenticationContext';
import { useEffect, useMemo } from 'react';
import useDoStatsExistForMeetings from './answer/hooks/useDoStatsExistForMeetings';
import useMeetingSubscription from '@/services/subscriptions/useMeetingSubscription';
import useMeetingUserAccessForUser from '@/services/hooks/useMeetingUserAccessForUser';

import { goToLiveMeetingPage } from '@/utils/helpers/goToLiveMeetingPage';
import LiveMeetingInteractionsMenu from '@/components/LiveMeetingInteractionsMenu/LiveMeetingInteractionsMenu';
import { MEETING_STATUS, getMeetingStatus } from './helpers/getMeetingStatus';
import PopConfirm from '@/ui/PopConfirm/PopConfirm';
import {
  pureCleanMeetingData,
  pureRestartMeeting,
} from '@/backend/pureRestartMeeting';

// --------------------- styled ------------------------

function MeetingListView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, isAdmin, userClients } = useUserAuthenticationContext();
  const { t } = useTranslation();

  const { userAccessList } = useMeetingUserAccessForUser(user?.id);
  // recover full meeting list
  const originalMeetingList = useAppSelector(MeetingSelectors.getMeetingList);

  const meetingList = useMemo(() => {
    if (!originalMeetingList || !user) return [];
    let toReturn = originalMeetingList.filter(
      (meeting) => meeting.isArchived === false
    );
    toReturn = getAvailableUserMeetings(
      toReturn,
      user,
      userClients,
      userAccessList,
      isAdmin
    );
    return toReturn;
  }, [originalMeetingList, userAccessList.length, user, userClients]);

  // filter to use only available meetings

  const statsExistForMeeting = useDoStatsExistForMeetings(meetingList);

  useMeetingSubscription();

  // --------------------- methods ------------------------

  // handle meeting join click
  const handleMeetingJoin = async (
    meetingID: string,
    testMode: boolean = false
  ) => {
    goToLiveMeetingPage({
      meetingID,
      testMode,
    });
  };

  const handleMeetingStats = (meetingID: string) => {
    navigate(`${ROUTES.MEETING_STATS}/${meetingID}`);
  };

  // --------------------- effects ------------------------

  // ping user
  // TODO: merge this with auto checking network status
  useUserMeetingPing(user);

  // fetch meetings
  useEffect(() => {
    dispatch(fetchMeetingList());
  }, [dispatch]);

  // track
  useEffect(() => {
    if (user?.id) {
      trackPageView({ pageUrl: 'home', pageTitle: 'home', userId: user?.id });
    }
  }, [user?.id]);

  // SIMPLE auto connect if only one meeting today
  useEffect(() => {
    if (meetingList && meetingList.length === 1) {
      // ok we have only one meeting in list, just check if the date is today
      const meeting = meetingList[0];
      const today = new Date().toDateString();
      const eventDate = new Date(meeting.eventDate).toDateString();
      if (today === eventDate && meeting.host?.id !== user?.id)
        handleMeetingJoin(meeting.id);
    }
  }, [meetingList]);

  // --------------------- render ------------------------

  return (
    <PageLayout>
      <Box p="20px" bg="livelinx.grey100" h="100%">
        {user && meetingList.length == 0 && (
          <Fade in={true} delay={1}>
            <Box h="full">
              <Center h="100px">
                <Heading size="sm" color="grey">
                  {t('home.noMeeting')}
                </Heading>
              </Center>
            </Box>
          </Fade>
        )}

        {meetingList.length > 0 && user && (
          <Fade in={true}>
            <MeetingList
              data={meetingList}
              showGuestUrl={isAdmin}
              actions={[
                {
                  render: (item: Meeting) => {
                    const doStatsExistForThisMeeting =
                      statsExistForMeeting[item.id];
                    const status = getMeetingStatus(item);
                    const isHost = item?.host?.id === user.id;

                    // main button
                    const mainButtonVisible = [
                      MEETING_STATUS.DEMO,
                      MEETING_STATUS.READY,
                      MEETING_STATUS.TESTING,
                      MEETING_STATUS.STARTED,
                    ].includes(status);

                    const startDemoButton: ButtonProps = {
                      children: t('admin.home.table.startDemo.button'),
                      variant: 'ghost',
                      onClick: async () => {
                        await pureCleanMeetingData(item.id);
                        await handleMeetingJoin(item.id);
                      },
                    };
                    const joinDemoButton: ButtonProps = {
                      children: t('admin.home.table.joinDemo.button'),
                      variant: 'ghost',
                      onClick: async () => {
                        await handleMeetingJoin(item.id);
                      },
                    };
                    const startTestingButton: ButtonProps = {
                      children: t('admin.home.table.startTesting.button'),
                      variant: 'primary',
                      onClick: async () => {
                        await pureCleanMeetingData(item.id);
                        await handleMeetingJoin(item.id, true);
                      },
                    };
                    const joinTestingButton: ButtonProps = {
                      children: t('admin.home.table.joinTesting.button'),
                      variant: 'primary',
                      onClick: async () => {
                        await handleMeetingJoin(item.id);
                      },
                    };

                    const startMeeting: ButtonProps = {
                      children: t('admin.home.table.startMeeting.button'),
                      variant: 'danger',
                      // no on click as we do this in the pop confirm
                      // onClick: async() =>{
                      // }
                    };
                    const joinMeetingButton: ButtonProps = {
                      children: t('admin.home.table.joinMeeting.button'),
                      variant: 'primary',
                      onClick: async () => {
                        await handleMeetingJoin(item.id);
                      },
                    };

                    const currentButtonProps = (() => {
                      if (status == MEETING_STATUS.DEMO)
                        return isHost || isAdmin
                          ? startDemoButton
                          : joinDemoButton;
                      if (status == MEETING_STATUS.TESTING)
                        return isHost || isAdmin
                          ? startTestingButton
                          : joinTestingButton;
                      if (status == MEETING_STATUS.READY && isAdmin)
                        return startMeeting;
                      // default
                      return joinMeetingButton;
                    })();

                    const startAfterConfirmation = async () => {
                      await pureRestartMeeting(item.id);
                      await handleMeetingJoin(item.id);
                    };

                    return (
                      <Flex direction="column" gap={2} width="150px">
                        {isAdmin && status === MEETING_STATUS.READY ? (
                          <PopConfirm
                            title="You will now start the Event meeting. Are you sure?" // TODO: translate
                            onConfirm={startAfterConfirmation}
                          >
                            <Button
                              variant="primary"
                              isDisabled={!mainButtonVisible}
                              size="md"
                              w="100%"
                              {...currentButtonProps}
                            />
                          </PopConfirm>
                        ) : (
                          <Button
                            variant="primary"
                            size="md"
                            isDisabled={!mainButtonVisible}
                            w="100%"
                            {...currentButtonProps}
                          />
                        )}

                        {isAdmin && (
                          <>
                            <LiveMeetingInteractionsMenu meeting={item} />
                          </>
                        )}

                        {isHost && (
                          <>
                            {doStatsExistForThisMeeting && (
                              <Button
                                variant="secondary"
                                size="xs"
                                onClick={() => {
                                  handleMeetingStats(item.id);
                                }}
                              >
                                {t('admin.home.table.meetingStats.button')}
                              </Button>
                            )}
                          </>
                        )}
                      </Flex>
                    );
                  },
                },
              ]}
            />
          </Fade>
        )}
      </Box>
    </PageLayout>
  );
}

export default MeetingListView;
