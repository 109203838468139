import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Text,
  Icon,
  Grid,
  GridItem,
  Box,
  IconButton,
} from '@chakra-ui/react';

import { CaseMap } from '@/features/caseCluster/caseCluster.types';
import AnamneseItem, {
  AnamneseItemWrapper,
} from '@/components/CaseSlide/items/AnamneseItem';
import { getAnamenseItemFromCaseMap } from '@/features/caseCluster/helpers/getAnamneseItem';
import { SimpleContentModal } from '@/ui/SimpleContentModal';
import caseSelectorBg from '@/assets/images/liveMeetingBackground.svg?url';
import eyeIcon from '@/assets/images/eye.svg';
import MeetingTestingModeWarning from './MeetingTestingModeWarning';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import APP_CONSTANTS from '@/utils/constants/app.constants';
import ImageDisclaimer from './disclaimer/ImageDisclaimer';
import { RefreshIcon } from '@/components/icons/icons';
import { DisclaimerType } from '@/utils/types/enums';
import { reloadApp } from '@/utils/reloadApp';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';

type Props = {
  isHost: boolean;
  caseIDList: string[];
  caseMap: CaseMap | undefined;
  onCaseSelected: (caseID: string) => void;
};

function CaseSelectorView({
  isHost,
  caseIDList,
  caseMap,
  onCaseSelected,
}: Props) {
  const { t } = useTranslation();
  const numItems = caseIDList?.length;
  const [currentModal, setCurrentModal] = useState<string | undefined>();
  const { meeting: currentMeeting } = useCurrentMeetingDataContext();
  const { isAdmin } = useUserAuthenticationContext();

  const numCols = useMemo(() => {
    if (numItems == 4) return 2;
    if (numItems == 3 && window.innerWidth < window.innerHeight) return 1;
    return numItems > 3 ? 3 : numItems;
  }, [numItems]);

  const numRows = Math.ceil(numItems / numCols);
  const hasMultipleRows = numRows > 1;

  const onCaseSelectorItemClick = (caseID: string) => {
    if (isHost) {
      onCaseSelected(caseID);
    } else {
      setCurrentModal(caseID);
    }
  };

  return (
    <Flex
      bgImage={caseSelectorBg}
      backgroundSize="cover"
      bgColor="livelinx.purple400"
      h="100%"
      w="full"
      overflow="auto"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Box
        position="absolute"
        right="0"
        top="0"
        color="white"
        textAlign="right"
        pt="10px"
        pr="20px"
        display="flex"
        alignItems="center"
        gap="10px"
      >
        <Icon as={eyeIcon} boxSize="25px"></Icon>
        <Text>
          {t('home.cases.caseSelector.hostIsPresenting', {
            num: numItems,
          })}
        </Text>
      </Box>

      {currentMeeting?.isInTesting && (
        <MeetingTestingModeWarning isAdmin={isAdmin} />
      )}

      <Grid
        templateRows={`repeat(${numRows}, 1fr)`}
        templateColumns={`repeat(${numCols}, 1fr)`}
        gap="5vh"
        padding="5vh"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        {caseMap &&
          caseIDList?.map((caseID, caseIndex) => {
            //NOTE: This means that the caseIDList is not in sync with the caseMap, we haven't loaded the content yet
            if (!caseMap[caseID]) {
              console.warn(
                'We have not loaded the case ',
                caseID,
                ' yet so we are not displaying it yet'
              );
              console.warn('caseMap', caseMap);
              return;
            }
            const overrideItem = caseMap[caseID].caseSelectorItemOverride;

            // for loreal only, we override the case selector with the disclaimer for now
            // TODO: this is a HACK and must be added as feature OR removed
            const newCase = caseMap[caseID];
            if (
              !isHost &&
              newCase?.disclaimer &&
              newCase?.disclaimer.type == DisclaimerType.IMAGE &&
              newCase.id == 'lorealNL'
            ) {
              return (
                <Flex
                  key="screensaver"
                  top="0"
                  left="0"
                  right="0"
                  position="fixed"
                  zIndex="100"
                  background="white"
                  height="100%"
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ImageDisclaimer
                    disclaimer={newCase.disclaimer as ImageDisclaimer}
                    assetPath={`${APP_CONSTANTS.PATIENT_CASES_REPO_PATH}${newCase.path}`}
                  />
                  <IconButton
                    position="fixed"
                    right="25px"
                    bottom="15px"
                    size="md"
                    borderRadius="full"
                    aria-label="Refresh Button"
                    border="2px solid"
                    color="livelinx.blue200"
                    _hover={{
                      transform: 'scale(1.1)',
                      transition: 'all 0.2s ease-out',
                    }}
                    onClick={() => reloadApp()}
                    icon={<RefreshIcon />}
                  />
                </Flex>
              );
            }

            //if there is an override
            if (overrideItem) {
              return (
                <GridItem
                  key={caseID}
                  w="full"
                  h={hasMultipleRows ? '100%' : 'auto'}
                  overflow="hidden"
                  rowSpan={1}
                  colSpan={1}
                >
                  <AnamneseItemWrapper
                    onClick={() => {
                      onCaseSelectorItemClick(caseID);
                    }}
                  >
                    {overrideItem.title}
                  </AnamneseItemWrapper>
                </GridItem>
              );
            }

            const anamneseItem = getAnamenseItemFromCaseMap(caseMap, caseID);
            // case no Anamese found
            if (!anamneseItem) {
              console.error(`No anamneseItem found for case: ${caseID}`);
              captureSentry({
                title: 'No anamneseItem found for case',
                detail: { caseID, caseMap, caseIDList },
              });
              return (
                <GridItem
                  key={caseID}
                  overflow="hidden"
                  rowSpan={1}
                  colSpan={1}
                  color="red"
                >
                  {t('home.cases.caseSelector.noAnamneseItemFound')}
                </GridItem>
              );
            }

            return (
              <GridItem
                key={caseID}
                w="full"
                h={hasMultipleRows ? '100%' : 'auto'}
                overflow="hidden"
                rowSpan={1}
                colSpan={1}
              >
                <SimpleContentModal
                  isOpen={caseID === currentModal}
                  onClose={() => setCurrentModal(undefined)}
                  content={
                    <AnamneseItem
                      title={`CASUS ${caseIndex + 1}`}
                      isWomanCase={anamneseItem.gender === 'woman'}
                      subtitle={anamneseItem.title}
                      descriptionList={anamneseItem.descriptionList}
                    />
                  }
                />
                <AnamneseItem
                  title={`CASUS ${caseIndex + 1}`}
                  isWomanCase={anamneseItem.gender === 'woman'}
                  subtitle={anamneseItem.title}
                  descriptionList={anamneseItem.descriptionList}
                  isGridRowItem={hasMultipleRows}
                  onClick={() => {
                    onCaseSelectorItemClick(caseID);
                  }}
                />
              </GridItem>
            );
          })}
      </Grid>
      {/* <MeetingRightSideBar {...meetingRightSidebarProps} /> */}
    </Flex>
  );
}

export default CaseSelectorView;
