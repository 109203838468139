import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  FormSendToNPSConfirmationSchema,
  sendToNPSConfirmationSchema,
} from './sendToNPSConfirmationSchema';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInputItem from '@/ui/FormInputItem/FormInputItem';
import mutationUpdateMeetingAmountParticipants from '@/utils/graphql/mutations/mutationUpdateMeetingAmountParticipants';
import { Meeting } from '@/services/API';

type Props = {
  meeting: Meeting;
  disclosure: ReturnType<typeof useDisclosure>;
  onConfirm: () => void;
};

function NPSConfirmationLiveMeetingTypeModal({
  onConfirm,
  meeting,
  disclosure,
}: Props) {
  const { t } = useTranslation();
  const { register, handleSubmit, formState } =
    useForm<FormSendToNPSConfirmationSchema>({
      resolver: zodResolver(sendToNPSConfirmationSchema),
      shouldFocusError: true,
    });
  const { errors } = formState;

  const handleOnConfirm = async (data: FormSendToNPSConfirmationSchema) => {
    //NOTE: we are not calling trackCaseClusterStop(); here because
    // the function is only does a google analytics call and it would require a refactor
    // of useMeetingTracking to be able to use it here
    // This call is optionnal
    await mutationUpdateMeetingAmountParticipants(
      meeting.id,
      data.amountOfParticipants
    );
    disclosure.onClose();
    onConfirm();
  };
  return (
    <Modal
      isCentered
      scrollBehavior="inside"
      motionPreset="scale"
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
    >
      <ModalOverlay bgColor="blackAlpha.800" />
      <ModalContent borderRadius={4} boxShadow="dark-lg">
        <ModalHeader>{t('npsConfirmationModal.title')}</ModalHeader>
        <form onSubmit={handleSubmit(handleOnConfirm)}>
          <ModalBody>
            <Box>
              {t('liveMeetingInteractions.nps.confirmText')}
              <br />
              {t('meeting.header.confirmRestart')}
            </Box>
            <Box>
              <FormInputItem
                label={t('liveMeetingInteractions.nps.form.participants.label')}
                errorMessage={errors.amountOfParticipants?.message}
                {...register('amountOfParticipants', {
                  required: true,
                })}
                required
                type="number"
                placeholder="ex: 18"
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup size="sm">
              <Button variant="outline" onClick={disclosure.onClose}>
                {t('common.cancel')}
              </Button>
              <Button variant="blue" type="submit">
                {t('common.confirm')}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
        <ModalCloseButton color="black" />
      </ModalContent>
    </Modal>
  );
}

export default NPSConfirmationLiveMeetingTypeModal;
