import { SlideType } from '../enums';
import { Slide } from '../zod/slideTypes/slideSchema';
import { SlideShowSlideType } from '../zod/slideTypes/slideShowSlideSchema';

export default function isSlideSlideShow(
  slide: Slide
): slide is SlideShowSlideType {
  return slide.type === SlideType.SLIDE_SHOW;
}

export function isSlideNotSlideShow(
  slide: Slide
): slide is Exclude<Slide, SlideShowSlideType> {
  return slide.type !== SlideType.SLIDE_SHOW;
}
