import { PatientCase } from '@/utils/types/zod/patientCaseSchema';
import { SlideShowSlideType } from '@/utils/types/zod/slideTypes/slideShowSlideSchema';
import SlideShowSlideView from './SlideShowSlideView';
import { useCallback, useEffect } from 'react';
import findSlideInPatientCase from '@/utils/helpers/findSlideInPatientCase';
import useSlideVariations from '../hooks/useSlideVariation';
import { CaseSlideProps } from '../CaseSlide';
import { useSlideShowStore } from './slideShowData.store';

type Props = {
  slide: SlideShowSlideType;
  currentCase: PatientCase;
  caseSlideProps: CaseSlideProps;
};

export default function SlideShowSlideClient({
  slide,
  currentCase,
  caseSlideProps,
}: Props) {
  const slideShowStore = useSlideShowStore();
  const currentSubSlide =
    slideShowStore.subSlides[slideShowStore.currentSubSlideIdx];
  const variationDetails = useSlideVariations(
    currentCase.id,
    caseSlideProps.meeting,
    caseSlideProps.isHost,
    currentSubSlide,
    currentCase.variations,
    caseSlideProps.userID
  );

  const onChangeSubSlide = useCallback(
    (index: number) => {
      if (index < 0 || index >= slideShowStore.subSlides.length) {
        return;
      }
      slideShowStore.setCurrentSubSlideIdx(index);
    },
    [slideShowStore.setCurrentSubSlideIdx, slideShowStore.subSlides]
  );

  useEffect(() => {
    const newSubSlides = slide.items.map((item) => {
      const subSlide = findSlideInPatientCase(item.slideRef, currentCase);
      return subSlide;
    });
    slideShowStore.setSubslides(newSubSlides);
  }, [slide, currentCase]);

  return (
    <SlideShowSlideView
      subSlides={slideShowStore.subSlides}
      currentSubSlide={currentSubSlide}
      onChangeSubSlide={onChangeSubSlide}
      caseSlideProps={caseSlideProps}
      variationDetails={variationDetails}
    />
  );
}
