import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { PatientCase } from '@/utils/types/zod/patientCaseSchema';
import { Meeting } from '@/services/API';
import { Slide } from '@/utils/types/zod/slideTypes/slideSchema';
import CaseExplorerView from './CaseExplorerView';
import { useAnswersBySlide } from '@/services/hooks/useAnswersBySlide';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import useMeetingTrackingAssetStats from '@/features/tracking/useMeetingTrackingAssetStats';
import { useCurrentSlideData } from './currentSlideData.store';
import { useEffect } from 'react';

export type CaseExplorerClientProps = {
  patientCaseIndex: number;
  patientCase: PatientCase;
  isHost: boolean;

  meeting?: Meeting;
  forcedCurrSlide?: Slide | undefined;
  trackAssetSwitch?: ReturnType<
    typeof useMeetingTrackingAssetStats
  >['trackAssetSwitch'];
};

export default function CaseExplorerClient(props: CaseExplorerClientProps) {
  const { userID } = useUserAuthenticationContext();
  const { slideVariationDetails, currentHostAssetID } =
    useCurrentMeetingDataContext();
  const isPreview = !!props.forcedCurrSlide;
  const slideStore = useCurrentSlideData();

  let slideIndex =
    isPreview && !!props.forcedCurrSlide
      ? props.patientCase.slides.indexOf(props.forcedCurrSlide)
      : props.meeting?.currentSlide;
  if (!slideIndex || slideIndex < 0) {
    slideIndex = 0;
  }
  const currentSlide: Slide | undefined =
    props.forcedCurrSlide || props.patientCase?.slides?.[slideIndex];

  const answersForSlideDetails = useAnswersBySlide(slideIndex, isPreview);

  useEffect(() => {
    if (currentSlide) {
      slideStore.setCurrentSlide(currentSlide);
      slideStore.setCurrentSlideWithVariation(currentSlide);
    }
    if (
      !slideVariationDetails.isLoading &&
      slideVariationDetails.slideHasVariations &&
      slideVariationDetails.slideVariation
    ) {
      slideStore.setCurrentSlideWithVariation(
        slideVariationDetails.slideVariation.variation
      );
    }
  }, [currentSlide, slideVariationDetails]);

  return (
    <CaseExplorerView
      {...props}
      currSlideVariationDetails={slideVariationDetails}
      answersForSlideDetails={answersForSlideDetails}
      isPreview={isPreview}
      currentSlide={currentSlide}
      currentSlideWithVariation={slideStore.currentSlideWithVariation}
      slideIndex={slideIndex}
      userID={userID}
      currentHostAssetID={currentHostAssetID}
    />
  );
}
