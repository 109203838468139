import { Grid, Button, Flex, Icon, Box, Text, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaUser } from 'react-icons/fa';
import { Meeting, User } from '@/services/API';
import Popconfirm from '@/ui/PopConfirm/PopConfirm';
import meetingHeaderCaseBg from '@/assets/images/meetingHeaderCaseBg.svg?url';
import LiveMeetingTimer from '@/ui/Meeting/LiveMeetingTimer';
import useNetworkConnection from '@/utils/helpers/hooks/useNetwork';
import { MdOutlineSignalWifiOff, MdOutlineWifi } from 'react-icons/md';
import { usePubSubState } from '@/utils/helpers/hooks/usePubSubState';
import { MeetingTypes } from '@/features/caseCluster/caseCluster.types';
import { ROUTES } from '@/routes/Routes';

/* --------------------- comp ------------------------ */

type Props = {
  currentUser: User | undefined | null;
  currentMeeting: Meeting;
  currentCaseIndex: number;
  isHost: boolean;
  isAdmin: boolean;
  onRestartMeeting: () => void;
};

const LiveMeetingHeader = ({
  currentMeeting,
  currentCaseIndex,
  currentUser,
  isHost,
  isAdmin,
  onRestartMeeting,
}: Props) => {
  const { t } = useTranslation();
  const { online: isConnected } = useNetworkConnection();
  const pubsubState = usePubSubState();

  // --------------------- handlers ------------------------

  // --------------------- render ------------------------

  return (
    <Flex
      justifyContent="space-between"
      background="#FFF"
      borderBottom="1px solid rgb(233,233,233)"
      height="45px"
      gap={6}
      userSelect="none"
    >
      <Flex flexDirection="row" alignItems="center">
        {
          // [ THIS IS A HACK ]
          // TODO: Remove this by creating a functionnality to remove the CASUS part of a case cluster
          currentMeeting?.caseCluster?.cases?.some(
            (x) => x !== 'lorealNL' && x !== 'lorealFR'
          ) && (
            <Flex
              alignItems="center"
              padding="0px 20px 0px 15px"
              color="#FFF"
              fontFamily="poppins-regular"
              fontWeight="bold"
              fontSize="12px"
              height="45px"
              style={{
                borderImage: `url(${meetingHeaderCaseBg})`,
                borderImageSlice: '25 fill',
                borderImageWidth: '48px 48px',
              }}
            >
              {currentMeeting?.caseCluster?.name}
              {currentCaseIndex !== -1
                ? ` / CASUS ${currentCaseIndex + 1}`
                : ''}
            </Flex>
          )
        }
        <Box
          paddingLeft="15px"
          color="livelinx.black100"
          fontSize="15px"
          fontFamily="nunito-regular"
          fontWeight="500"
        >
          {`${currentMeeting?.name}`}
        </Box>
      </Flex>

      {/* // --------------------- username + network ------------------------ */}

      <Flex
        paddingRight="10px"
        height="100%"
        flexDirection="row"
        // alignItems="center"
        gap="5px"
      >
        {isHost && (
          <LiveMeetingTimer
            key={currentMeeting.currentSlide}
            showAfterSecond={60 * 5}
          />
        )}
        <HStack color="primary" pr="10px">
          <Box
            border="1px solid"
            borderColor="primary"
            borderRadius="100%"
            w="25px"
            h="25px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon as={FaUser} fontSize="15px" />
          </Box>
          <Text
            fontSize="16px"
            paddingRight="10px"
            style={{
              fontVariant: 'small-caps',
            }}
          >
            {currentUser?.email.split('@')[0]}{' '}
          </Text>
          <Icon
            as={isConnected ? MdOutlineWifi : MdOutlineSignalWifiOff}
            color="primary"
            fontSize="15px"
          />
          <Text fontSize="xs" pt="2px" ml="-6px">{`${pubsubState}`}</Text>
        </HStack>

        {/* // --------------------- ADMIN: Back to home button ------------------------ */}
        {isAdmin && (
          <Flex h="full" alignItems="center">
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                window.location.href = ROUTES.HOME;
              }}
            >
              DASHBOARD
            </Button>
          </Flex>
        )}

        {/* // --------------------- restart button ------------------------ */}

        {isAdmin && currentMeeting.meetingType !== MeetingTypes.LIVE && (
          <Grid>
            <Popconfirm
              title={
                <div>
                  {t('meeting.header.restartTitle')}
                  <br />
                  {t('meeting.header.confirmRestart')}
                </div>
              }
              onConfirm={onRestartMeeting}
              okText={t('common.yes') as string}
              cancelText={t('common.no') as string}
            >
              <Button
                padding="15px 23px  15px 21px"
                borderRadius="5px"
                size="md"
                variant="primary"
              >
                {t('meeting.header.restartBtn')}
              </Button>
            </Popconfirm>
          </Grid>
        )}
      </Flex>
    </Flex>
  );
};

export default LiveMeetingHeader;
