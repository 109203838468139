import { useMemo } from 'react';
import AdminClickupListView from './AdminClickupListView';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import {
  CLICKUP_API_TOKEN,
  CLICKUP_LIST_IDS,
} from '@/utils/constants/app.constants';
import { PageLoading } from '@/ui/PageLoading';

export default function AdminClickupListClient() {
  const clickupTasksRequest = useQuery({
    queryKey: ['clickupTasksRequest'],
    refetchOnWindowFocus: false,
    enabled: true,
    queryFn: async () => {
      const response = await axios.get(
        `https://api.clickup.com/api/v2/list/${CLICKUP_LIST_IDS.LIVE_EVENT}/task`,
        {
          headers: {
            Authorization: CLICKUP_API_TOKEN,
          },
        }
      );

      return response.data;
    },
  });
  const data = useMemo(() => {
    if (!clickupTasksRequest.data || clickupTasksRequest.isLoading) return [];
    return clickupTasksRequest.data.tasks;
  }, [clickupTasksRequest.data]);

  if (clickupTasksRequest.isLoading) {
    return <PageLoading />;
  }
  return <AdminClickupListView data={data} />;
}
