import { createColumnHelper } from '@tanstack/react-table';
import { PatientCase } from '@/utils/types/zod/patientCaseSchema';
import {
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { FiEdit, FiEye, FiMoreVertical } from 'react-icons/fi';
import { MdOutlineAutorenew } from 'react-icons/md';
import { TFunction } from 'i18next';

export type AdminCaseListRowType = PatientCase & {
  numberOfSlides: number;
};

const columnHelper = createColumnHelper<AdminCaseListRowType>();

type Args = {
  t: TFunction<'translation', undefined, 'translation'>;
  onViewCaseClick: (data: PatientCase) => void;
  onPreviewLinkClick: (data: PatientCase) => void;
  onEditCaseClick: (data: PatientCase) => void;
};

const getColumns = ({
  t,
  onViewCaseClick,
  onPreviewLinkClick,
  onEditCaseClick,
}: Args) => {
  const columns = [
    columnHelper.accessor('id', {
      header: 'ID',
      cell: (props) => props.row.original.id,
    }),
    columnHelper.accessor('displayName', {
      header: 'Name',
    }),
    columnHelper.accessor('numberOfSlides', {
      header: '# of Slides',
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: (props) => {
        const data = props.row.original;
        return (
          <HStack>
            <Button onClick={() => onViewCaseClick(data)} variant="secondary">
              <FiEye />
              <Text ml="5px">{t('admin.cases.table.viewCase.button')}</Text>
            </Button>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreVertical />}
                variant="outline"
              />
              <MenuList>
                <MenuItem
                  icon={<MdOutlineAutorenew />}
                  onClick={() => {
                    onPreviewLinkClick(data);
                  }}
                >
                  {t('admin.cases.table.actions.generatePreview.button')}
                </MenuItem>
                <MenuItem
                  icon={<FiEdit />}
                  onClick={() => {
                    onEditCaseClick(data);
                  }}
                >
                  {' '}
                  {t('common.edit')}
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        );
      },
      minSize: 200,
    }),
  ];

  return columns;
};

export default getColumns;
