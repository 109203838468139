import InfoSlide from './InfoSlide';
import AnamneseSlide from './AnamneseSlide';
import isSlideQuestionSlide from '@/utils/types/type-validation/isSlideQuestionSlide';
import QuestionSlide from './QuestionSlide/QuestionSlide';
import QuestionContextProvider from './QuestionSlide/context/QuestionContext';
import { Slide } from '@/utils/types/zod/slideTypes/slideSchema';
import { Box, Text } from '@chakra-ui/react';
import { CustomSlides, SlideType } from '@/utils/types/enums';
import useSlideVariations from './hooks/useSlideVariation';
import { AppSpinner } from '@/ui/AppSpinner';
import LorealSkinExpertSlide from '@/customSlides/loreal/SkinExpertSlide/LorealSkinExpertSlide';
import CustomSlide2 from '@/customSlides/loreal/CustomSlide2/CustomSlide2';
import CustomSlide3 from '@/customSlides/loreal/CustomSlide3/CustomSlide3';
import LorealPromoterStats from '@/customSlides/loreal/LorealPromoterStats/LorealPromoterStats';
import ImageSlide from './ImageSlide';
import { Asset } from '@/utils/types/zod/assetSchema';
import { PatientCase } from '@/utils/types/zod/patientCaseSchema';
import { UseAnswersBySlideReturnType } from '@/services/hooks/useAnswersBySlide';
import { Meeting } from '@/services/API';
import SlideShowSlideClient from './SlideShowSlide/SlideShowSlideClient';
import { useSlideID } from './hooks/useSlideID';

export type CaseSlideProps = {
  caseIndex: number;
  userID: string | undefined;
  isHost: boolean;
  slide: Slide;
  slideIndex: number;
  assetList: Asset[];
  currentCase: PatientCase | undefined;
  meeting: Meeting | undefined;
  onShowAssets: (assetRefs: string[]) => void;
  isInViewMode?: boolean;
  variationDetails: ReturnType<typeof useSlideVariations> | null;
  answersForSlideDetails: UseAnswersBySlideReturnType;
  currentHostAssetID: string | null | undefined;
  hideSlideID?: boolean;
};

const Container = ({ children }: { children: React.ReactNode }) => (
  <Box
    width="100%"
    height="100%"
    overflow="hidden"
    backgroundColor="transparent"
    position="relative"
  >
    {children}
  </Box>
);

const CaseSlide = (props: CaseSlideProps) => {
  const slideID = useSlideID();
  if (
    props.variationDetails &&
    props.variationDetails.slideHasVariations &&
    props.variationDetails.isLoading
  ) {
    return <AppSpinner />;
  }

  if (
    props.variationDetails &&
    props.variationDetails.slideHasVariations &&
    !props.variationDetails.isLoading &&
    props.variationDetails.slideVariation
  ) {
    return (
      <CaseSlide
        {...props}
        key={props.variationDetails.slideVariation.variationId}
        slide={props.variationDetails.slideVariation.variation}
        variationDetails={null}
        //NOTE: in order to be able to have infinite variations inside variations inside other variations, we would
        //need to pass the variations of the variation slide, which is not currently the case as we don't want that feature
      />
    );
  }

  if (!props.slide) return null;
  if (!props.currentCase) {
    console.warn('CaseSlide: currentCase is undefined');
    return null;
  }
  return (
    <Container>
      {props.slide.type === SlideType.NORMAL && (
        <InfoSlide
          slide={props.slide}
          onShowAssets={props.onShowAssets}
          assetList={props.assetList}
          currentHostAssetID={props.currentHostAssetID}
        />
      )}
      {props.slide.type === SlideType.SLIDE_SHOW && (
        <SlideShowSlideClient
          slide={props.slide}
          currentCase={props.currentCase}
          caseSlideProps={props}
        />
      )}
      {props.slide.type === SlideType.ANAMNESE && (
        <AnamneseSlide slide={props.slide} caseIndex={props.caseIndex} />
      )}
      {props.slide.type === SlideType.IMAGE && (
        <ImageSlide slide={props.slide} casePath={props.currentCase?.path} />
      )}
      {/* Question & Custom slides */}
      {(isSlideQuestionSlide(props.slide) ||
        props.slide.type === SlideType.CUSTOM) && (
        <QuestionContextProvider
          slide={props.slide}
          userID={props.userID}
          meeting={props.meeting}
          currentCase={props.currentCase}
          isUserHost={props.isHost}
          answersForSlideDetails={props.answersForSlideDetails}
        >
          {isSlideQuestionSlide(props.slide) && (
            <QuestionSlide
              slide={props.slide}
              isPreview={props.isInViewMode ?? false}
              isHost={props.isHost}
              onShowAssets={props.onShowAssets}
              assetList={props.assetList}
              currentHostAssetID={props.currentHostAssetID}
            />
          )}
          {props.slide.type === SlideType.CUSTOM &&
            props.slide.customSlide === CustomSlides.LOREAL_DRAG_AND_DROP && (
              <Container>
                <LorealSkinExpertSlide slide={props.slide} />
              </Container>
            )}
          {props.slide.type === SlideType.CUSTOM &&
            props.slide.customSlide === CustomSlides.LOREAL_CUSTOM_2 && (
              <Container>
                <CustomSlide2 slide={props.slide} />
              </Container>
            )}
          {props.slide.type === SlideType.CUSTOM &&
            props.slide.customSlide === CustomSlides.LOREAL_CUSTOM_3 && (
              <Container>
                <CustomSlide3 slide={props.slide} idx={0} />
              </Container>
            )}
          {props.slide.type === SlideType.CUSTOM &&
            props.slide.customSlide === CustomSlides.LOREAL_CUSTOM_4 && (
              <Container>
                <CustomSlide3 slide={props.slide} idx={1} />
              </Container>
            )}
          {props.slide.type === SlideType.CUSTOM &&
            props.slide.customSlide === CustomSlides.LOREAL_CUSTOM_5 && (
              <Container>
                <CustomSlide3 slide={props.slide} idx={2} />
              </Container>
            )}
          {props.slide.type === SlideType.CUSTOM &&
            props.slide.customSlide ===
              CustomSlides.LOREAL_AS_PROMOTER_STATS && (
              <Container>
                <LorealPromoterStats />
              </Container>
            )}
        </QuestionContextProvider>
      )}

      {!props.hideSlideID && (
        <Text
          position="absolute"
          bottom={1}
          right={2}
          fontSize="xs"
          color="gray.300"
        >
          {slideID}
        </Text>
      )}
    </Container>
  );
};

export default CaseSlide;
