import { AssetType } from '@/utils/types/enums';
import { Asset } from '@/utils/types/zod/assetSchema';
import { Flex, Image } from '@chakra-ui/react';

type Props = {
  assetList: Asset[];
  currentIndex: number;
  onChangeIndex: (index: number) => void;
};

function AssetListPreview({ onChangeIndex, assetList, currentIndex }: Props) {
  return (
    <Flex gap={3} m={4}>
      {assetList.map((elem, idx) => {
        return (
          <Flex
            boxSize={16}
            key={elem.id}
            borderRadius="10px"
            position="relative"
            overflow="hidden"
            border="2px solid"
            borderColor={
              idx === currentIndex ? 'livelinx.orange200' : 'transparent'
            }
            cursor="pointer"
            onClick={() => onChangeIndex(idx)}
            alignItems="center"
            justifyContent="center"
            bg="white"
            boxShadow="whiteShadow"
          >
            <Flex
              position="absolute"
              width="100%"
              height="100%"
              zIndex={1}
              bg={
                idx === currentIndex
                  ? 'livelinx.orange200Opacity40'
                  : 'transparent'
              }
              alignItems="center"
              justifyContent="center"
            />
            <Image
              src={elem.type === AssetType.IMAGE ? elem.url : elem.thumbUrl}
            />
          </Flex>
        );
      })}
    </Flex>
  );
}

export default AssetListPreview;
