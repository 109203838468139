import { mutationDeleteAllNPSScoresForMeeting } from '@/features/meeting-stats/graphql/delete';
import { mutationDeleteAllAnswersForMeeting } from '@/features/meeting/answer/mutationDeleteAnswer';
import mutationDeleteAllMessagesForMeeting from '@/features/meeting/messages/mutationDeleteAllMessagesForMeeting';
import { UpdateMeetingInput, UpdateMeetingMutation } from '@/services/API';
import { updateMeeting } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import pureResetCaseStatsForMeeting from './pureResetMeetingStats';
import mutationResetMeetingStats from './utils/mutationResetMeetingStats';
import { NULL_ID_VALUE } from '@/utils/constants/app.constants';

/*
- Responsible for resetting a meeting to its initial state.
*/
export const pureCleanMeetingData = async (
  meetingID: string,
  andStartMeeting?: boolean
) => {
  // clean meeting data
  await mutationDeleteAllAnswersForMeeting(meetingID);
  await mutationDeleteAllMessagesForMeeting(meetingID);
  await pureResetCaseStatsForMeeting(meetingID);
  await mutationDeleteAllNPSScoresForMeeting(meetingID);
  await mutationResetMeetingStats(meetingID);

  // reset meeting
  const input: UpdateMeetingInput = {
    id: meetingID,
    currentCase: NULL_ID_VALUE,
    currentSlide: 0,
  };

  // restart if needed
  if (andStartMeeting) input.startDate = new Date().toISOString();

  await callGraphQLApi<GraphQLResult<UpdateMeetingMutation>>(updateMeeting, {
    input,
  });
};

export const pureRestartMeeting = async (meetingID: string) => {
  await pureCleanMeetingData(meetingID, true);
};
