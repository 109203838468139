import { SlideType } from '../enums';
import { AnamneseSlideType } from '../zod/slideTypes/anamneseSlideSchema';
import { Slide } from '../zod/slideTypes/slideSchema';

export default function isSlideAnamneseSlide(
  slide: Slide
): slide is AnamneseSlideType {
  return slide.type === SlideType.ANAMNESE;
}

export function isSlideNotAnamneseSlide(
  slide: Slide
): slide is Exclude<Slide, AnamneseSlideType> {
  return slide.type !== SlideType.ANAMNESE;
}
