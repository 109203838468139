import {
  Meeting,
  UpdateMeetingMutation,
  UpdateMeetingMutationVariables,
} from '@/services/API';
import {
  Button,
  ButtonProps,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import { updateMeeting } from '@/services/graphql/mutations';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import ConfirmModal from '@/ui/ConfirmModal/ConfirmModal';
import {
  CodeSandboxOutlined,
  ReloadOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { NPSConfirmationModal } from './NPSConfirmationModal/NPSConfirmationModal';
import {
  MEETING_STATUS,
  getMeetingStatus,
} from '@/features/meeting/helpers/getMeetingStatus';
import {
  pureCleanMeetingData,
  pureRestartMeeting,
} from '@/backend/pureRestartMeeting';

type Props = {
  meeting: Meeting;
  buttonProps?: ButtonProps;
};

export default function LiveMeetingInteractionsMenu({
  meeting,
  buttonProps,
}: Props) {
  const { t } = useTranslation();
  const toast = useToast();
  const npsDisclosure = useDisclosure();
  const restartDisclosure = useDisclosure();
  const meetingStatus = getMeetingStatus(meeting);

  const sandboxModeEnabled = [
    MEETING_STATUS.IDLE,
    MEETING_STATUS.READY,
    MEETING_STATUS.TESTING,
  ].includes(meetingStatus);

  const sendNPSEnabled = [
    MEETING_STATUS.DEMO,
    MEETING_STATUS.STARTED,
    MEETING_STATUS.TESTING,
  ].includes(meetingStatus);

  const restartMeetingEnabled = [
    MEETING_STATUS.READY, // actually we also want to be able to restart meetings in the READY state (it's the same as start meeting)
    MEETING_STATUS.STARTED,
    MEETING_STATUS.DEMO,
    MEETING_STATUS.TESTING,
  ].includes(meetingStatus);

  const handleMeetingRestart = async (meetingID: string) => {
    if (meetingStatus === MEETING_STATUS.TESTING)
      await pureCleanMeetingData(meetingID);
    else await pureRestartMeeting(meetingID);
  };

  const handleSandboxChange = async (meetingID: string, newValue: boolean) => {
    if (newValue) {
      const variables: UpdateMeetingMutationVariables = {
        input: {
          id: meetingID,
          isInTesting: true,
        },
      };
      await callGraphQLApi<GraphQLResult<UpdateMeetingMutation>>(
        updateMeeting,
        variables
      );
    } else {
      const variables: UpdateMeetingMutationVariables = {
        input: {
          id: meeting.id,
          isInTesting: false,
        },
      };

      await callGraphQLApi<GraphQLResult<UpdateMeetingMutation>>(
        updateMeeting,
        variables
      );
      await pureCleanMeetingData(meetingID);
    }
  };

  if (meeting.isArchived) return null;
  return (
    <>
      <NPSConfirmationModal meeting={meeting} disclosure={npsDisclosure} />
      <ConfirmModal
        title={
          <div>
            {t('admin.home.table.restartMeeting.button.confirmText')}
            <br />
            {t('meeting.header.confirmRestart')}
          </div>
        }
        onConfirm={() => {
          handleMeetingRestart(meeting.id)
            .then(() =>
              toast({
                title: 'Meeting restarted',
                status: 'success',
                duration: 5000,
                isClosable: true,
              })
            )
            .catch((err) => {
              captureSentry({
                title: 'Error restarting meeting',
                error: err instanceof Error ? err : undefined,
                detail: {
                  meetingID: meeting.id,
                },
              });
              toast({
                title: 'Error restarting meeting',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            });
        }}
        disclosure={restartDisclosure}
      />
      <Menu>
        <MenuButton as={Button} variant="secondary" size="xs" {...buttonProps}>
          {t('liveMeetingInteractions.btn.title')}
        </MenuButton>
        <MenuList>
          <MenuItem
            closeOnSelect={false}
            icon={<CodeSandboxOutlined />}
            isDisabled={!sandboxModeEnabled}
            onClick={() => {
              handleSandboxChange(meeting.id, !meeting.isInTesting)
                .then(() => {
                  toast({
                    title: 'SandBox mode changed',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                  });
                })
                .catch((err) => {
                  captureSentry({
                    title: 'Error changing sandbox mode',
                    error: err instanceof Error ? err : undefined,
                    detail: {
                      meetingID: meeting.id,
                    },
                  });
                  toast({
                    title: 'Error changing sandbox mode',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  });
                });
            }}
          >
            <HStack width="100%" justifyContent="space-between">
              <Text>{t('liveMeetingInteractions.btn.test.label')}</Text>
              <Switch variant="blue" isChecked={!!meeting.isInTesting}></Switch>
            </HStack>
          </MenuItem>
          <MenuItem
            icon={<StarOutlined />}
            onClick={npsDisclosure.onOpen}
            isDisabled={!sendNPSEnabled}
          >
            {t('liveMeetingInteractions.btn.nps.label')}
          </MenuItem>
          <MenuItem
            icon={<ReloadOutlined />}
            onClick={restartDisclosure.onOpen}
            isDisabled={!restartMeetingEnabled}
          >
            {t('liveMeetingInteractions.btn.restart.label')}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
