import useOnScreen from '@/utils/hooks/useOnScreen';
import isSlideAnamneseSlide from '@/utils/types/type-validation/isSlideAnamneseSlide';
import { Slide } from '@/utils/types/zod/slideTypes/slideSchema';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Flex, Button } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

type Props = {
  slideIndex: number;
  slides: Slide[];
  isHost: boolean;

  onSlideChange: (slideIndex: number) => void;
  onHomeClick: () => void;
  isInViewMode?: boolean;
};

function CaseSlideNavigator({
  slideIndex,
  slides,
  isHost,
  onSlideChange,
  onHomeClick,
  isInViewMode,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const firstSlideBtnRef = useRef<HTMLButtonElement>(null);
  const lastSlideBtnRef = useRef<HTMLButtonElement>(null);
  const isFirstSlideBtnVisible = useOnScreen(firstSlideBtnRef);
  const isLastSlideBtnVisible = useOnScreen(lastSlideBtnRef);

  const handleButtonClick = (slide: number) => {
    if (!isHost) return;
    if (slide === -1) {
      onHomeClick();
    } else {
      onSlideChange(slide);
    }
  };
  const hasPatientCase = !!slides.find(isSlideAnamneseSlide);
  const buttonSlides = slides.map((x, i) => `button_${i}`);

  useEffect(() => {
    if (containerRef.current) {
      //NOTE: Button width + the gap between buttons
      const buttonWidth = 50 + 8;

      const containerWidth = containerRef.current.offsetWidth;
      const offset = containerWidth / 2 - buttonWidth / 2;
      const target = slideIndex * buttonWidth - offset;

      containerRef.current.scrollTo({
        left: target,
        behavior: 'smooth',
      });
    }
  }, [slideIndex]);

  return (
    <Flex
      /* NOTE: we have to set the width here, it does not matter to what, but it's needed for the
      live actions button and refresh button to be able to be shown */
      w="50%"
      alignItems="center"
      overflow="clip"
      flexGrow={1}
      flexShrink={1}
      px={2}
    >
      <Flex gap="8px">
        {!isInViewMode && (
          <Button
            variant="slideNavigator"
            onClick={() => {
              handleButtonClick(-1);
            }}
          >
            <HomeOutlined />
          </Button>
        )}
        {hasPatientCase && (
          <Button
            variant={
              slideIndex === 0 ? 'slideNavigatorSelected' : 'slideNavigator'
            }
            flex="1 1 auto"
            data-test="nav_item_0"
            onClick={() => {
              handleButtonClick(0);
            }}
            minWidth="50px"
          >
            <UserOutlined />
          </Button>
        )}
      </Flex>
      <Flex position="relative" overflowX="hidden" flexGrow={1}>
        <Flex
          ref={containerRef}
          padding="10px"
          alignItems="center"
          gap="8px"
          overflowX="hidden"
          overflowY="hidden"
          data-test="slide-navigator"
          w="full"
          sx={{
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              bottom: 0,
              width: 10,
              zIndex: 1,
              backgroundImage: `linear-gradient(to right, rgba(214, 218, 255, 1) 0%, rgba(214, 218, 255, 0) 100%)`,
              left: 0,
              pointerEvents: 'none',
              opacity: isFirstSlideBtnVisible ? 0 : 1,
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              width: 10,
              zIndex: 1,
              backgroundImage: `linear-gradient(to left, rgba(214, 218, 255, 1) 0%, rgba(214, 218, 255, 0) 100%)`,
              pointerEvents: 'none',
              opacity: isLastSlideBtnVisible ? 0 : 1,
            },
          }}
        >
          {buttonSlides.map((buttonName, index) => {
            if (hasPatientCase && index === 0) return null;
            return (
              <Button
                ref={
                  index === (hasPatientCase ? 1 : 0)
                    ? firstSlideBtnRef
                    : index === slides.length - 1
                    ? lastSlideBtnRef
                    : null
                }
                key={`${buttonName}_${index === slideIndex ? 'selected' : ''}`}
                variant={
                  index === slideIndex
                    ? 'slideNavigatorSelected'
                    : 'slideNavigator'
                }
                flex="1 1 auto"
                data-test={`nav_item_${index}`}
                onClick={() => {
                  handleButtonClick(index);
                }}
                minWidth="50px"
              >
                {hasPatientCase && index !== 0 && index}
                {!hasPatientCase && index + 1}
              </Button>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CaseSlideNavigator;
