import { useEffect, useMemo, useState } from 'react';
import Empty from '@/ui/Empty/Empty';
import AssetModal from '@/components/AssetModal/AssetModal';
import CaseSlide from '../../../components/CaseSlide/CaseSlide';
import { Trans, useTranslation } from 'react-i18next';
import { HStack, Flex, Heading, keyframes, Divider } from '@chakra-ui/react';
import liveMeetingBackground from '@/assets/images/liveMeetingBackground.svg?url';
import { SimpleContentModal } from '@/ui/SimpleContentModal';
import AnamneseItem from '@/components/CaseSlide/items/AnamneseItem';
import MeetingTestingModeWarning from '../MeetingTestingModeWarning';
import { Slide } from '@/utils/types/zod/slideTypes/slideSchema';
import { Asset } from '@/utils/types/zod/assetSchema';
import { getAssetListByRefOrThrow } from '../utils/getAssetByRef';
import { getAnamneseItemFromCase } from '@/features/caseCluster/helpers/getAnamneseItem';
import { fadeInLeft } from 'react-animations';
import { getIndexFirstSlideNotAnamnese } from '../utils/getIndexFirstSlideNotAnamnese';
import TopRightItem from '../TopRightItem';
import { UseSlideVariationReturnType } from '@/components/CaseSlide/hooks/useSlideVariation';
import { CaseExplorerClientProps } from './CaseExplorerClient';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import { UseAnswersBySlideReturnType } from '@/services/hooks/useAnswersBySlide';
import MeetingLeftSidebar from '../MeetingLeftSidebar/MeetingLeftSidebar';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { useSlideTitle } from './hooks/useSlideTitle';
import { useSlideShowStore } from '@/components/CaseSlide/SlideShowSlide/slideShowData.store';
import { SlideType } from '@/utils/types/enums';

export type CaseExplorerViewProps = Omit<
  CaseExplorerClientProps,
  'forcedCurrSlide'
> & {
  currSlideVariationDetails: UseSlideVariationReturnType;
  answersForSlideDetails: UseAnswersBySlideReturnType;
  currentSlide: Slide | undefined;
  currentSlideWithVariation: Slide | null;
  slideIndex: number;
  isPreview: boolean;
  userID: string | undefined;
  currentHostAssetID: string | null | undefined;
};

const CaseExplorerView = ({
  patientCaseIndex,
  patientCase,
  meeting,
  isHost,
  isPreview,
  currentSlide,
  currentSlideWithVariation,
  slideIndex,
  userID,
  currSlideVariationDetails,
  answersForSlideDetails,
  trackAssetSwitch,
  currentHostAssetID,
}: CaseExplorerViewProps) => {
  const { t } = useTranslation();
  const fromRight = keyframes`${fadeInLeft}`;

  const [modalAssets, setModalAssets] = useState<Asset[] | undefined>();
  const [modalAssetIndex, setModalAssetIndex] = useState<number>(0);
  const [showPatientProfile, setShowPatientProfile] = useState<boolean>(false);
  const { isAdmin } = useUserAuthenticationContext();

  const anamenseItem = getAnamneseItemFromCase(patientCase);
  const shouldRenderLLXBackground =
    slideIndex < getIndexFirstSlideNotAnamnese(patientCase);
  const slideTitle = useSlideTitle();
  const slideShowStore = useSlideShowStore();

  const slideForLeftSidebar = useMemo(() => {
    if (currentSlideWithVariation?.type === SlideType.SLIDE_SHOW) {
      return slideShowStore.subSlides[slideShowStore.currentSubSlideIdx];
    }
    return currentSlideWithVariation;
  }, [currentSlideWithVariation, slideShowStore]);

  // --------------------- handlers ------------------------

  const showMoreInfoAssets = (assetRefs: string[]) => {
    const assetList = getAssetListByRefOrThrow(assetRefs, patientCase.assets);
    setModalAssetIndex(0);
    trackAssetSwitch &&
      trackAssetSwitch({
        newAssetID: assetList[0].id,
        assetName: assetList[0].title,
        isLibraryAsset: false,
      });
    setModalAssets(assetList);
  };

  const showLibraryAsset = (assetRef: string | undefined) => {
    if (!slideForLeftSidebar) {
      captureSentry({
        title: 'showLibraryAsset: currentSlide is undefined',
        detail: {
          slideForLeftSidebar,
          assetRef,
          meetingID: meeting?.id,
          patientCaseID: patientCase.id,
          slideIndex,
        },
      });
      return;
    }
    const assetList = getAssetListByRefOrThrow(
      slideForLeftSidebar.libraryRefs,
      patientCase.assets
    );
    let index = assetList.findIndex((item) => item.id === assetRef);
    index = index === -1 ? 0 : index;
    const asset = assetList[index];

    if (trackAssetSwitch && assetRef)
      trackAssetSwitch({
        newAssetID: assetRef,
        assetName: asset.path,
        isLibraryAsset: true,
      });

    setModalAssetIndex(index);
    setModalAssets(assetList);
  };

  /*
  NOTE: If the slide changes and the modal assets are still open, we close them
  */

  useEffect(() => {
    // if (previousSlideIndex !== slideIndex) {
    setModalAssets(undefined);
    // }
  }, [slideIndex]);

  // --------------------- effect ------------------------

  /*
  Note: I think this code is useless but I'm not sure so I'm just commenting it before deleting it
  */

  // useEffect(() => {
  //   const filteredModalAssets = currentSlide?.items
  //     .filter((slide) => slide.assets && slide.assets?.length > 0)
  //     .filter((data) =>
  //       data.assets?.filter(
  //         (asset) =>
  //           modalAssets && modalAssets.some((el) => asset.url === el.url)
  //       )
  //     );

  //   if (filteredModalAssets) {
  //     setModalAssets(undefined);
  //   }
  // }, [currentSlide?.id]);

  // --------------------- render ------------------------

  if (!currentSlide || !slideForLeftSidebar) {
    // CASE EMPTY
    return (
      <Flex w="full" justifyContent="center">
        <Empty>{t('noSlideFound')}</Empty>
      </Flex>
    );
  }

  return (
    <Flex
      direction="column"
      height="100%"
      w="full"
      overflow="hidden"
      position="relative"
    >
      <Flex justifyContent="space-between">
        <Flex
          flexDirection="column"
          padding="25px 20px 10px 60px"
          backgroundColor="white"
        >
          <Heading
            color="livelinx.purple200"
            fontSize="1.5em"
            animation={`0.7s ${fromRight}`}
            userSelect="none"
          >
            <Trans>{slideTitle}</Trans>
          </Heading>

          <HStack justifyContent="space-between" marginBottom="5px">
            <Flex alignItems="center" gap="20px" marginTop="5px"></Flex>
          </HStack>
        </Flex>
        {slideForLeftSidebar.topRight && (
          <TopRightItem
            item={slideForLeftSidebar.topRight}
            slides={patientCase.slides}
          />
        )}
      </Flex>
      <Divider />
      <Flex
        flex="auto"
        pt="10px"
        backgroundSize="cover"
        flexDirection="column"
        position="relative"
        backgroundColor={
          shouldRenderLLXBackground ? 'livelinx.purple400' : 'white'
        }
        backgroundImage={shouldRenderLLXBackground ? liveMeetingBackground : ''}
      >
        {meeting?.isInTesting && (
          <MeetingTestingModeWarning isAdmin={isAdmin} />
        )}
        <CaseSlide
          key={currentSlide.id} // this wont be updated without this...
          // key={currentSlide!.id + '' + answers.length} // this wont be updated without this...
          isHost={isHost}
          caseIndex={patientCaseIndex}
          slide={currentSlide}
          slideIndex={slideIndex}
          onShowAssets={(assetList) => {
            showMoreInfoAssets(assetList);
          }}
          isInViewMode={isPreview}
          assetList={patientCase.assets}
          currentCase={patientCase}
          meeting={meeting}
          userID={userID}
          variationDetails={currSlideVariationDetails}
          answersForSlideDetails={answersForSlideDetails}
          currentHostAssetID={currentHostAssetID}
        />
      </Flex>
      {modalAssets && (
        <AssetModal
          index={modalAssetIndex}
          assetList={modalAssets}
          onAssetChange={(oldAsset: Asset, newAsset: Asset) => {
            trackAssetSwitch &&
              trackAssetSwitch({
                newAssetID: newAsset.id,
                assetName: newAsset.path,
                isLibraryAsset: false,
              });
          }}
          onModalClose={() => {
            trackAssetSwitch && trackAssetSwitch(null);
            setModalAssets(undefined);
          }}
        />
      )}
      {anamenseItem && (
        <SimpleContentModal
          isOpen={showPatientProfile}
          onClose={() => setShowPatientProfile(false)}
          content={
            <AnamneseItem
              title={`CASES ${patientCaseIndex + 1}`}
              isWomanCase={anamenseItem.gender === 'woman'}
              subtitle={anamenseItem.title}
              descriptionList={anamenseItem.descriptionList}
            />
          }
        />
      )}
      <MeetingLeftSidebar
        patientCase={patientCase}
        currentSlide={slideForLeftSidebar}
        slideIndex={slideIndex}
        onPatientCaseClick={() => setShowPatientProfile(true)}
        onSupportingDocsClick={() => showLibraryAsset(undefined)}
      />
    </Flex>
  );
};

export default CaseExplorerView;
