import { Meeting } from '@/services/API';

export const calculateMeetingDuration = (meeting: Meeting): number => {
  const startTime = new Date(meeting.startDate || '').getTime();
  const endTime = new Date(meeting.endDate || '').getTime();

  if (!startTime && !endTime) return 0;
  if (startTime) {
    const calculationEndTime = endTime || Date.now();
    const durationMins = Math.floor(
      (calculationEndTime - startTime) / (1000 * 60)
    );
    return durationMins;
  }
  return -1;
};
