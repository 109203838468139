import useCaseClusterList from '@/features/caseCluster/hooks/useCaseClusterList';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { useUserList } from '@/services/hooks/useUserList';
import useMeetingSubscription from '@/services/subscriptions/useMeetingSubscription';
import { MeetingSelectors } from '@/store/slices/meeting';
import { useAppSelector } from '@/store/StoreHooks';
import { PageLoading } from '@/ui/PageLoading';
import AdminMeetingListView from './AdminMeetingListView';
import { useMemo } from 'react';
import { AdminMeetingListItemType } from './columns';
import useAllClientList from '@/features/clients/hooks/useAllClientList';
import {
  getMeetingStatus,
  getMeetingStatusText,
} from '@/features/meeting/helpers/getMeetingStatus';
import { calculateMeetingDuration } from '@/utils/calculateMeetingDuration';

// type Props = {};

const AdminMeetingListClient = () => {
  const meetingList = useAppSelector(MeetingSelectors.getMeetingList);
  const caseClusterlist = useCaseClusterList();
  const userList = useUserList();
  const { user } = useUserAuthenticationContext();
  const clientsList = useAllClientList();

  // const statsExistForMeeting = useDoStatsExistForMeetings(meetingList);

  // usesubscription to be sure everything is updated once updated on backend
  useMeetingSubscription();

  const data: AdminMeetingListItemType[] = useMemo(() => {
    return meetingList.map((meeting) => {
      const meetingClientList =
        clientsList?.filter((elem) => meeting.clientIDs.includes(elem.id)) ||
        [];
      return {
        ...meeting,
        clients: meetingClientList,
        clientText: meetingClientList.map((elem) => elem.clientName).join(', '),
        meetingStatus: getMeetingStatus(meeting),
        meetingStatusText: getMeetingStatusText(getMeetingStatus(meeting)),
        meetingDurationMinutes: calculateMeetingDuration(meeting),
      };
    });
  }, [meetingList, clientsList]);

  if (!user || !caseClusterlist || !clientsList) {
    return <PageLoading />;
  }
  return (
    <AdminMeetingListView
      data={data}
      caseClusterList={caseClusterlist}
      user={user}
      userList={userList}
      clientsList={clientsList}
    />
  );
};

export default AdminMeetingListClient;
