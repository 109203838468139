import { parseCaseAssets } from '@/utils/helpers/parseCaseAssets';
import { Heading, Flex, IconButton } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import CaseSlideNavigator from '../caseCluster/CaseSlideNavigator';
import { MdArrowBackIos } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/Routes';
import PageLayout from '@/ui/Layout/PageLayout';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import {
  PatientCase,
  UnloadedIPatientCase,
} from '@/utils/types/zod/patientCaseSchema';
import CurrentMeetingDataContextProvider from '@/features/meeting/context/CurrentMeetingDataContext';
import { fetchCaseData } from '@/features/caseCluster/helpers/fetchCaseData';
import useShowDisclaimer from '@/services/hooks/useShowDisclaimer';
import APP_CONSTANTS from '@/utils/constants/app.constants';
import CaseDisclaimerView from '../caseCluster/disclaimer/CaseDisclaimerView';
import CaseExplorerClient from '../caseCluster/CaseExplorer/CaseExplorerClient';

type Props = {
  unloadedPatientCase: UnloadedIPatientCase;
};

export default function CaseView({ unloadedPatientCase: caseData }: Props) {
  const [caseDataView, setCaseDataView] = useState<PatientCase | undefined>();
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const { isAdmin } = useUserAuthenticationContext();
  const showDisclaimer = useShowDisclaimer({
    currentCase: caseDataView,
    isNPSScorePage: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!caseData) navigate(ROUTES.HOME);
    fetchCaseData([caseData.id], [caseData.path]).then((res) => {
      const parsedCase = parseCaseAssets(res[0]);
      setCaseDataView(parsedCase);
    });
  }, [caseData]);

  return (
    <PageLayout showHeader={false} showFooter={false}>
      <Flex
        alignItems="center"
        background="white"
        boxShadow="0px 2px 10px #f4f4f4"
        padding="5px"
        zIndex={1}
      >
        {isAdmin && (
          <IconButton
            rounded="100%"
            aria-label="back-button"
            pl="5px"
            icon={<MdArrowBackIos fontSize="20px" />}
            onClick={() =>
              navigate(`${ROUTES.ADMIN_CASE_LIST}`, { replace: true })
            }
          />
        )}
        <Heading fontSize="15px" pl="20px" color="livelinx.grey200">
          {caseData.name}
        </Heading>
      </Flex>
      {caseData?.id && caseDataView && (
        <Flex h="100%" w="100%" direction="column">
          <CurrentMeetingDataContextProvider
            isPreview
            forcedPatientCase={caseDataView}
          >
            {caseDataView.disclaimer && !!showDisclaimer ? (
              <CaseDisclaimerView
                disclaimer={caseDataView.disclaimer}
                assetPath={`${APP_CONSTANTS.PATIENT_CASES_REPO_PATH}${caseDataView.path}`}
              />
            ) : (
              <CaseExplorerClient
                patientCaseIndex={0}
                patientCase={caseDataView}
                isHost={true}
                forcedCurrSlide={caseDataView?.slides?.[slideIndex]}
              />
            )}
          </CurrentMeetingDataContextProvider>
          <CaseSlideNavigator
            slideIndex={slideIndex as number}
            // totalSlides={caseDataView?.slides?.length || 0}
            slides={caseDataView.slides || []}
            onSlideChange={(slide) => {
              setSlideIndex(slide);
            }}
            onHomeClick={() => {
              console.log('here!');
              //  onHomeClick();
            }}
            isHost
            isInViewMode
          />
        </Flex>
      )}
    </PageLayout>
  );
}
