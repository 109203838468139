import { useQuery } from '@tanstack/react-query';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import {
  CaseSlideStats,
  CaseStats,
  CaseStatsByMeetingQuery,
  OnUpdateCaseSlideStatsSubscription,
} from '@/services/API';
import { caseStatsByMeeting } from '@/services/graphql/queries';
import { useEffect, useMemo, useState } from 'react';
import useNewSubscriptions from '@/services/subscriptions/useNewSubscriptions';
import { onUpdateCaseSlideStats } from '@/services/graphql/subscriptions';
import { getCaseIdFromCaseStatsList } from '../utils/getCaseIdFromCaseStatsList';
import useMeetingCaseStatsSanityCheck from './sanityChecks/useMeetingCaseStatsSanityCheck';
import useMeetingStatsSanityCheck from './sanityChecks/useMeetingStatsSanityCheck';
// import { getCaseIdFromCaseStatsList } from ;

export const useMeetingCaseStatsDetails = (
  meetingID: string | null | undefined,
  caseClusterID: string | null | undefined,
  live = false
): Record<string, CaseStats> => {
  const [caseStatsDetailsFormatted, setCaseStatsDetailsFormatted] = useState<
    Record<string, CaseStats>
  >({});

  const caseStatsQuery = useQuery({
    queryKey: ['getCaseStatsByMeeting', meetingID],
    refetchOnWindowFocus: false,
    enabled: !!meetingID,
    queryFn: async () => {
      return await callGraphQLApi<GraphQLResult<CaseStatsByMeetingQuery>>(
        caseStatsByMeeting,
        {
          meetingID: meetingID,
        }
      );
    },
  });
  useMeetingStatsSanityCheck(meetingID);
  useMeetingCaseStatsSanityCheck(meetingID, caseClusterID, true);

  const caseStatsDetails = caseStatsQuery.data?.data?.caseStatsByMeeting?.items;

  //keep updated as changes happen on caseSlideStats and update the caseStats that we maintain in state
  if (live) {
    const subscriptionOptions = useMemo(() => {
      if (!caseStatsDetails) return [];
      return caseStatsDetails.map((item) => {
        return {
          query: onUpdateCaseSlideStats,
          variables: {
            caseStatsSlidesId: item?.id,
          },
          callback: (data: OnUpdateCaseSlideStatsSubscription) => {
            const caseStatsID = data?.onUpdateCaseSlideStats?.caseStatsSlidesId;
            if (!caseStatsID) {
              console.warn('useMeetingCaseStatsDetails: caseStatsID is null');
              return;
            }
            //get the caseID from the caseStatsID
            const caseID = getCaseIdFromCaseStatsList(
              caseStatsID,
              caseStatsDetails as CaseStats[]
            );
            if (!caseID) {
              console.warn('useMeetingCaseStatsDetails: caseID is null');
              return;
            }
            setCaseStatsDetailsFormatted((prev) => {
              const newObj: Record<string, CaseStats> = {
                ...prev,
              };
              //remove the existing slide
              if (!newObj[caseID].slides?.items) {
                return prev;
              }
              const indexToRemove = newObj[caseID].slides?.items.findIndex(
                (item) => item?.id === data.onUpdateCaseSlideStats?.id
              );
              //Note: be carefull not to put if (indextoRemove) because this returns false when indexToRemove is 0
              if (indexToRemove !== undefined && indexToRemove >= 0) {
                newObj[caseID].slides?.items.splice(indexToRemove, 1);
              } else {
                console.warn(
                  'useMeetingCaseStatsDetails: indexToRemove null. This means that the updated slide was not in the list of slides.'
                );
              }
              //add the new slide info
              newObj[caseID].slides?.items.push(
                data.onUpdateCaseSlideStats as CaseSlideStats
              );
              return newObj;
            });
          },
        };
      });
    }, [caseStatsDetails]);
    useNewSubscriptions(
      subscriptionOptions,
      'useMeetingCaseStatsDetails onUpdateCaseSlideStats'
    );
  }

  //populating when data is available
  useEffect(() => {
    if (!caseStatsDetails) return;
    const newCaseDetails = caseStatsDetails.reduce((acc, curr) => {
      if (!curr) return acc;
      acc[curr.caseId] = curr;
      return acc;
    }, {} as Record<string, CaseStats>);

    setCaseStatsDetailsFormatted(newCaseDetails);
  }, [caseStatsDetails]);

  return caseStatsDetailsFormatted;
};
