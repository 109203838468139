import { z } from 'zod';
import { SlideType } from '../../enums';
import { baseSlideSchema } from './baseSlideSchema';
import { slideShowItemSchema } from './slideItemTypes/slideShowItemSchema';

export const slideShowSlideSchema = baseSlideSchema.and(
  z.object({
    type: z.literal(SlideType.SLIDE_SHOW),

    items: z.array(slideShowItemSchema),
  })
);

export type SlideShowSlideType = z.infer<typeof slideShowSlideSchema>;
