import { Slide } from '@/utils/types/zod/slideTypes/slideSchema';
import { create } from 'zustand';

type SlideShowStore = {
  currentSubSlideIdx: number;
  subSlides: Slide[];

  setCurrentSubSlideIdx: (slideIdx: number) => void;
  setSubslides: (subslides: Slide[]) => void;
};

export const useSlideShowStore = create<SlideShowStore>((set) => ({
  currentSubSlideIdx: 0,
  subSlides: [],

  setCurrentSubSlideIdx: (slideIdx: number) =>
    set({ currentSubSlideIdx: slideIdx }),
  setSubslides: (subslides: Slide[]) => set({ subSlides: subslides }),
}));
