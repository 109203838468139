import { SlideType } from '@/utils/types/enums';
import { useCurrentSlideData } from '../currentSlideData.store';
import { useSlideShowStore } from '@/components/CaseSlide/SlideShowSlide/slideShowData.store';

export const useSlideTitle = () => {
  const currentSlide = useCurrentSlideData((state) => state.currentSlide);
  const currentSlideWithVariation = useCurrentSlideData(
    (state) => state.currentSlideWithVariation
  );
  const slideShowStore = useSlideShowStore();

  if (!currentSlide) return undefined;
  let result = currentSlide.title;

  if (currentSlide && currentSlide.type === SlideType.SLIDE_SHOW) {
    result += ` / ${
      slideShowStore.subSlides[slideShowStore.currentSubSlideIdx].title
    }`;
  }
  if (
    currentSlideWithVariation &&
    currentSlideWithVariation.id !== currentSlide?.id
  ) {
    result += ` / ${currentSlideWithVariation.title}`;
    if (currentSlideWithVariation.type === SlideType.SLIDE_SHOW) {
      const slideShowSlide =
        slideShowStore.subSlides[slideShowStore.currentSubSlideIdx];
      result += ` / ${slideShowSlide.title}`;
    }
  }
  return result;
};
