import {
  UpdateMeetingMutation,
  UpdateMeetingMutationVariables,
} from '@/services/API';
import { updateMeeting } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';

export const mutationUpdateHostAsset = async (
  meetingID: string,
  newAssetID: string | null
) => {
  const variables: UpdateMeetingMutationVariables = {
    input: {
      id: meetingID,
      currentAssetID: newAssetID ? newAssetID : '',
    },
  };
  const result = await callGraphQLApi<UpdateMeetingMutation>(
    updateMeeting,
    variables
  );
  return result;
};
