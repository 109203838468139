import { captureSentry } from '@/utils/helpers/sentryHelper';
import { Asset } from '@/utils/types/zod/assetSchema';

export function getAssetByRefOrThrow(assetID: string, assets: Asset[]): Asset {
  const asset = assets.find((asset) => asset.id === assetID);
  if (!asset) {
    captureSentry({
      title: `getAssetByRefOrThrow: Asset with ID ${assetID} not found`,
      detail: {
        assetID,
        assets,
      },
    });
    throw new Error(`Asset with ID ${assetID} not found`);
  }
  return asset;
}

export function getAssetListByRefOrThrow(
  assetIDList: string[],
  assets: Asset[]
): Asset[] {
  const assetList = assets.filter((asset) => assetIDList.includes(asset.id));
  //check if all assets are found
  if (assetList.length !== assetIDList.length) {
    captureSentry({ title: `getAssetListByRefOrThrow: Not all assets found` });
    throw new Error(`Not all assets found`);
  }
  assetList.sort(
    (a, b) => assetIDList.indexOf(a.id) - assetIDList.indexOf(b.id)
  );
  return assetList;
}
