import { ROUTES } from '@/routes/Routes';
import {
  PatientCase,
  UnloadedIPatientCase,
} from '@/utils/types/zod/patientCaseSchema';
import {
  Box,
  Flex,
  HStack,
  Spacer,
  useDisclosure,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CasePreviewLinkModal from './CasePreviewLinkModal/CasePreviewLinkModal';
import getColumns, { AdminCaseListRowType } from './columns';
import useCaseListTableStore from './CaseListTableStore';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { fuzzyFilter } from '@/utils/helpers/fuzzyFilter';
import TanstackTableView from '@/ui/TanstackTable/TanstackTableView';
import Filters from '@/ui/TanstackTable/Filter/Filters';
import GlobalFilter from '@/ui/TanstackTable/Filter/GlobalFilter/GlobalFilter';
import ActiveFilters from '@/ui/TanstackTable/Filter/ActiveFilters/ActiveFilters';
import Pagination from '@/ui/TanstackTable/Pagination';
import ColumnVisibility from '@/ui/TanstackTable/Columns/ColumnVisibility';
import { FilterType } from '@/ui/TanstackTable/Filter/NewFilterComponent';

type Props = {
  data: AdminCaseListRowType[];
  unloadedCases: UnloadedIPatientCase[];
};

const CaseListView = ({ data, unloadedCases }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const previewLinkModal = useDisclosure();
  const [previewLinkModalCase, setPreviewLinkModalCase] =
    useState<PatientCase | null>(null);

  const handleViewCase = async (patientCase: PatientCase) => {
    const unloadedPatientCase = unloadedCases.find(
      (el) => el.id === patientCase.id
    );
    navigate(`${ROUTES.PREVIEW_CASE}/admin`, { state: unloadedPatientCase });
  };
  const handleEditCase = async (patientCase: PatientCase) => {
    navigate(`${ROUTES.ADMIN_CASE_EDIT}?id=${patientCase.id}`);
  };

  const columns = getColumns({
    t,
    onViewCaseClick: (elem) => {
      handleViewCase(elem);
    },
    onPreviewLinkClick: (elem) => {
      setPreviewLinkModalCase(elem);
      previewLinkModal.onOpen();
    },
    onEditCaseClick: (elem) => {
      handleEditCase(elem);
    },
  });

  const [
    columnFilters,
    setColumnFilters,
    columnVisibility,
    setColumnVisibility,
    columnSizing,
    setColumnSizing,
  ] = useCaseListTableStore((state) => [
    state.columnFilters,
    state.setColumnFilters,
    state.columnVisibility,
    state.setColumnVisibility,
    state.columnSizing,
    state.setColumnSizing,
  ]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const table = useReactTable({
    data,
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    state: {
      columnSizing,
      columnVisibility,
      columnFilters,
      globalFilter,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: (updater) => {
      const newVisibility =
        typeof updater === 'function' ? updater(columnVisibility) : updater;
      setColumnVisibility(newVisibility);
    },
    onColumnSizingChange: (updater) => {
      const newSizing =
        typeof updater === 'function' ? updater(columnSizing) : updater;
      setColumnSizing(newSizing);
    },
    defaultColumn: {},
    columnResizeMode: 'onEnd',
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns: columns,
  });
  const filterTypeMappings: Partial<
    Record<keyof UnloadedIPatientCase, FilterType>
  > = {
    // NOTE: right now there are no specific filters needed in this table
    // but I leave this in the codebase for future reference
  };
  const possibleFilterOptions = {};

  return (
    <Box p={{ base: 3, md: 3 }}>
      {previewLinkModalCase && (
        <CasePreviewLinkModal
          disclosure={previewLinkModal}
          patientCase={previewLinkModalCase}
        />
      )}
      <HStack justifyContent="space-between" my={2}>
        <chakra.h3 fontSize="2xl" fontWeight="bold" textAlign="center">
          {t('admin.cases.list.title')}{' '}
        </chakra.h3>
      </HStack>
      <Flex gap="20px" justifyContent="center" alignItems="center" mb="4">
        <VStack w="full">
          <HStack w="full">
            <ColumnVisibility table={table} />
            <Filters
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              table={table}
              filterTypeMapping={filterTypeMappings}
              filterOptionsMapping={possibleFilterOptions}
            />
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              maxWidth="250px"
            />
            <ActiveFilters
              columnFilters={columnFilters}
              removeItemFunc={(elem) => {
                setColumnFilters(columnFilters.filter((f) => f.id !== elem.id));
              }}
              filterTypeMapping={filterTypeMappings}
            />
            <Spacer />
            <Pagination table={table} />
          </HStack>
        </VStack>
      </Flex>
      <TanstackTableView table={table} />
    </Box>
  );
};

export default CaseListView;
