import { PatientCase } from '../types/zod/patientCaseSchema';
import { Slide } from '../types/zod/slideTypes/slideSchema';
import { captureSentry } from './sentryHelper';

/**
 * Finds a slide in a patient case by slide ID
 * Assumes that the slide will be found otherwise throws an error
 * @param slideID - The ID of the slide to find
 * @param patientCase - The patient case to search in
 * @returns The slide if found, otherwise undefined
 */
export default function findSlideInPatientCase(
  slideID: string,
  patientCase: PatientCase
): Slide {
  const slide = patientCase.slides.find((slide) => slide.id === slideID);
  if (!slide) {
    captureSentry({
      title: `findSlideInPatientCase: Slide with ID ${slideID} not found in patient case`,
      detail: {
        caseID: patientCase.id,
        patientCase,
        slideID,
      },
    });
    throw new Error(
      `findSlideInPatientCase: Slide with ID ${slideID} not found in patient case`
    );
  }
  return slide;
}
